import gallery1Img from "../assets/images/gallery-images/gallery-img-1.jpg";
import gallery2Img from "../assets/images/gallery-images/gallery-img-2.jpg";
import gallery3Img from "../assets/images/gallery-images/gallery-img-3.png";
import gallery4Img from "../assets/images/gallery-images/gallery-img-4.jpg";
import gallery5Img from "../assets/images/gallery-images/gallery-img-5.jpg";
import gallery6Img from "../assets/images/gallery-images/gallery-img-6.png";
import gallery7Img from "../assets/images/gallery-images/gallery-img-7.png";
import S3CLink from "../components/S3CLink";

const galleryList = [
  {
    id: 0,
    caption: (
      <p>
        <strong className='gallery-coalition-title'>Community of Practice in action</strong>
        In November 2023, all coalitions gathered in Mumbai, India to both share their progress and discuss strategies to overcome common challenges.
        Coalitions shared their learnings, laid the groundwork for expanded advocacy work in 2024 and forged new connections across their group.{" "}
      </p>
    ),
    image: gallery1Img
  },
  {
    id: 1,
    caption: (
      <p>
        <strong className='gallery-coalition-title'>Kenya Quality Ecosystem</strong>
        <em className='gallery-coalition-italics'>The Kenya Quality Ecosystem team who supports the PROMPTS app.</em> Leveraging the power of AI, the
        PROMPTS app provides real-time support for expectant mothers. One mother signed up for PROMPTS at her local health facility and described
        using the digital platform to learn more about her pregnancy and nutrition-related information. One week, she noticed she was bleeding and
        messaged PROMPTS. She got an immediate response recommending that she seek care at the nearest hospital. When she arrived, she described how
        the nurses stabilized her and gave her medicine, making her feel supported. After her hospital visit, PROMPTS even followed up to understand
        her experience.
      </p>
    ),
    image: gallery2Img
  },
  {
    id: 2,
    caption: (
      <p>
        <strong className='gallery-coalition-title'>MamaLink </strong>
        <em className='gallery-coalition-italics'>The MamaLink team who provides critical ambulatory care to mothers in need in Kenya.</em> A
        42-year-old mother who had delivered via C-Section – but sadly had lost the child – at Pumwani Maternity Hospital was unconscious. Because she
        was still under anasthesia, had developed blood clotting complications and had bled excessively, she urgently needed specialized care at
        Kenyatta National Hospital. In her condition, she needed an Advanced Cardiac Life Support (ACLS) Ambulance with a ventilator in order to get
        to Kenyatta National Hospital. Because of the MamaLink team’s speed and responsiveness, she was evacuated within 10 minutes and admitted into
        Kenyatta’s ICU, resulting in her discharge from the ICU in a stable condition for normal recovery the very next day.
      </p>
    ),
    image: gallery3Img
  },
  {
    id: 3,
    caption: (
      <p>
        <strong className='gallery-coalition-title'>Wellbody</strong>
        <em className='gallery-coalition-italics'>Providers gather at a Wellbody facility to care for mothers with the help of Janitri.</em> A
        35-year-old woman from the Sierra Leone-Guinea border had experienced three stillbirth deliveries. After her fourth pregnancy, she delivered a
        baby girl in Kombayendeh – a village with a community health center. This marked the first time she ever heard one of her babies cry. After
        completing the post-delivery checklist on Janitri’s device, the midwife recorded that the baby had a low birthweight and referred them for
        advanced care quickly, skillfully catching early signs of future complications. This mom has since been back to Kombayendeh with her healthy
        baby girl to get the appropriate childhood vaccines.
      </p>
    ),
    image: gallery4Img
  },
  {
    id: 4,
    caption: (
      <p>
        <strong className='gallery-coalition-title'>e-SAATHI</strong>
        <em className='gallery-coalition-italics'>
          A provider speaks to a mother to understand her experience and overview the e-SAATHI tools including{" "}
          <S3CLink disclaimer href='https://www.nivi.io/' target='_blank'>
            <a>askNivi</a>
          </S3CLink>
          .
        </em>
        AskNivi is a digital solution that provides helpful guidance, tips, and reminders throughout a woman’s pregnancy journey. This tool is quite
        valuable to expectant moms – and a member of the Women’s Ambassador Forum appreciated the usefulness of the tool, noting that she wished that
        it was available while she was pregnant. Other women have identified the tailored and localized nature of the messages to be most helpful. One
        mom said “I got to know the correct way and process on how to breast feed after I deliver” and “It was interesting to know about the locally
        and easily available food which are important for me and my baby.”
      </p>
    ),
    image: gallery5Img
  },
  {
    id: 5,
    caption: (
      <p>
        <strong className='gallery-coalition-title'>Project Aisha</strong>
        <em className='gallery-coalition-italics'>A member of the Project Aisha team speaking about their work.</em> Project Aisha has a strong focus
        on sustainability and is working collaboratively with the Lagos Primary Health Care Board to co-design, establish and pilot a Quality
        Improvement (QI) mentorship program for primary health care providers in the state. Eleven experienced nurses/midwives were selected in the
        two Local Government Areas (LGAs) as QI mentors with supervision from the LGA Health Manager- Medical Officers for Health. The activities of
        the mentorship program are guided by a codeveloped terms of reference and the program is being monitored using appropriate electronic monthly
        reporting templates and checklists for ease of tracking implementation.
      </p>
    ),
    image: gallery6Img
  },
  {
    id: 6,
    caption: (
      <p>
        <strong className='gallery-coalition-title'>FOR M(om)</strong>
        <em className='gallery-coalition-italics'>
          The FOR M(om) team which support the Helium Health tools including Helium Electronic Medical record.
        </em>
        St. Luke's Hospital faced challenges, including unreliable power supply and a decrease in community demand for in-facility care. Through this
        program, which enabled the installation of the Helium Electronic Medical record, healthcare delivery has improved. As the health facility
        Managing Director expressed, "With the EMR from Helium Health, we hope to improve on our healthcare delivery," underlining the commitment to
        enhancing healthcare for mothers and families in the community. Loan financing was also provided to tackle the irregular power supply,
        facilitating the installation of a generator and a crucial ultrasound scanner within the facility.
      </p>
    ),
    image: gallery7Img
  }
];

export default galleryList;

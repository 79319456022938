const getBannerLottie = ({ bannerImage, positionX, positionY, direction }) => {
  let smallSquarePosX;
  let smallSquarePosY = positionY - 4;
  let largeSquareDegrees;
  let smallSquareDegrees;

  if (direction === 'textLeft') {
    largeSquareDegrees = 0;
    smallSquareDegrees = 0;
    smallSquarePosX = positionX + 924;
  } else if (direction === 'textRight') {
    smallSquareDegrees = 90;
    largeSquareDegrees = -90;
    smallSquarePosX = positionX - 924;
  }

  return {
    v: '5.9.0',
    fr: 30,
    ip: 0,
    op: 45,
    w: 3840,
    h: 2160,
    nm: 'Header',
    ddd: 0,
    assets: [{ id: 'image_0', w: 1445, h: 764, u: '', p: bannerImage, e: 0 }],
    layers: [
      {
        ddd: 0,
        ind: 1,
        ty: 4,
        nm: '.Square', // small green square
        cl: 'Square',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: smallSquareDegrees, ix: 10 }, // rotation
          p: { a: 0, k: [smallSquarePosX, smallSquarePosY, 0], ix: 2, l: 2 }, // position
          a: { a: 0, k: [1000, 12, 0], ix: 1, l: 2 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0, 0, 0], y: [1, 1, 1] },
                o: { x: [0.28, 0.28, 0.28], y: [0, 0, 0] },
                t: 26,
                s: [0, 0, 100]
              },
              { t: 45, s: [100, 100, 100] }
            ],
            ix: 6,
            l: 2
          }
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ty: 'rc',
                d: 1,
                s: { a: 0, k: [164, 164], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                r: { a: 0, k: 0, ix: 4 },
                nm: 'Rectangle Path 1',
                mn: 'ADBE Vector Shape - Rect',
                hd: false
              },
              {
                ty: 'st',
                c: { a: 0, k: [0, 0.521568627451, 0.486274509804, 1], ix: 3 },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 0, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false
              },
              {
                ty: 'fl',
                c: { a: 0, k: [0, 0.521568627451, 0.486274509804, 1], ix: 4 },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false
              },
              {
                ty: 'tr',
                p: { a: 0, k: [1082, 94], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform'
              }
            ],
            nm: 'Rectangle 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false
          }
        ],
        ip: 26,
        op: 45,
        st: -4,
        bm: 0
      },
      {
        ddd: 0,
        ind: 2,
        ty: 4,
        nm: '.maskpath',
        cl: 'maskpath',
        parent: 3,
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [-664, 688, 0], ix: 2, l: 2 },
          a: { a: 0, k: [-664, 688, 0], ix: 1, l: 2 },
          s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ty: 'rc',
                d: 1,
                s: { a: 0, k: [968, 968], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                r: { a: 0, k: 0, ix: 4 },
                nm: 'Rectangle Path 1',
                mn: 'ADBE Vector Shape - Rect',
                hd: false
              },
              {
                ty: 'st',
                c: { a: 0, k: [0, 0.521568627451, 0.486274509804, 1], ix: 3 },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 8, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-180, 204], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform'
              }
            ],
            nm: 'Rectangle 1',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false
          }
        ],
        ip: 0,
        op: 45,
        st: 0,
        bm: 0
      },
      {
        ddd: 0,
        ind: 3,
        ty: 4,
        nm: '.masksquare', // transparent square with border
        cl: 'masksquare',
        td: 1,
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: largeSquareDegrees, ix: 10 }, // only manipulate k - rotation
          p: { a: 0, k: [positionX, positionY, 0], ix: 2, l: 2 }, // only manipulate k - position
          a: { a: 0, k: [-664, 688, 0], ix: 1, l: 2 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.4, 0.4, 0.4], y: [1, 1, 1] },
                o: { x: [0.6, 0.6, 0.6], y: [0, 0, 0] },
                t: 0,
                s: [0, 0, 100]
              },
              { t: 30, s: [95.868, 95.868, 100] }
            ],
            ix: 6,
            l: 2
          }
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ty: 'rc',
                d: 1,
                s: { a: 0, k: [968, 968], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                r: { a: 0, k: 0, ix: 4 },
                nm: 'Rectangle Path 1',
                mn: 'ADBE Vector Shape - Rect',
                hd: false
              },
              {
                ty: 'st',
                c: {
                  a: 0,
                  k: [0.047058827269, 0.137254901961, 0.247058838489, 1],
                  ix: 3
                },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 0, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false
              },
              {
                ty: 'fl',
                c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-180, 204], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform'
              }
            ],
            nm: 'Rectangle 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false
          }
        ],
        ip: 0,
        op: 45,
        st: 0,
        bm: 0
      },
      {
        ddd: 0,
        ind: 4,
        ty: 2,
        nm: '.header',
        cl: 'header',
        tt: 1,
        refId: 'image_0',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [1920, 1080, 0], ix: 2, l: 2 },
          a: { a: 0, k: [722.5, 382, 0], ix: 1, l: 2 },
          s: { a: 0, k: [296.859, 296.859, 100], ix: 6, l: 2 }
        },
        ao: 0,
        ip: 0,
        op: 45,
        st: 0,
        bm: 0
      },
      {
        ddd: 0,
        ind: 5,
        ty: 1,
        nm: 'Overlay',
        sr: 1,
        ks: {
          o: { a: 0, k: 60, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [1920, 1080, 0], ix: 2, l: 2 },
          a: { a: 0, k: [1920, 1080, 0], ix: 1, l: 2 },
          s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
        },
        ao: 0,
        sw: 3840,
        sh: 2160,
        sc: '#0c233f',
        ip: 0,
        op: 45,
        st: 0,
        bm: 0
      },
      {
        ddd: 0,
        ind: 6,
        ty: 2,
        nm: '.background',
        cl: 'background',
        refId: 'image_0',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [1920, 1080, 0], ix: 2, l: 2 },
          a: { a: 0, k: [722.5, 382, 0], ix: 1, l: 2 },
          s: { a: 0, k: [296.859, 296.859, 100], ix: 6, l: 2 }
        },
        ao: 0,
        ip: 0,
        op: 45,
        st: 0,
        bm: 0
      }
    ],
    markers: []
  };
};

export default getBannerLottie;

import { useCallback, useState, useEffect } from "react";
import useEmblaCarousel from "embla-carousel-react";
import styled from "styled-components";
import Button from "../components/Button";
import S3CLink from "./S3CLink";
import storygalleryList from "../gallery/storiesGalleryList";
import styles from "../styles/ImgGallery.module.scss";
import { ReactComponent as LeftArrow } from "../assets/images/icons/left-arrow.svg";
import { ReactComponent as RightArrow } from "../assets/images/icons/right-arrow.svg";

const CarouselSlide = styled.div`
  flex: 0 0 33.3%;

  overflow: hidden;
  position: relative;
  transition-duration: 0.3s;
  padding-right: 5px;
  padding-left: 5px;

  &:hover {
    box-shadow: none;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    height: 300px;
  }

  @media screen and (max-width: 1270px) {
    flex: 0 0 100%;
    background-size: cover;
    padding: 0;
  }

  @media screen and (max-width: 720px) {
    img {
      height: 300px;
    }
  }

  //   .slideOverlay {
  //     width: 100%;
  //     height: 100%;
  //     background-color: rgba(12, 35, 66, 0.7);
  //     transition-duration: 0.3s;

  //     &:hover {
  //       opacity: 0;
  //     }
  //   }
`;

const ImgGallery = (props) => {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const [emblaRef, emblaApi] = useEmblaCarousel({
    containScroll: "trimSnaps",
    slidesToScroll: windowSize.innerWidth > 1270 ? 3 : 1
  });

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  return (
    <div className={styles.embla}>
      <div className='embla__viewport' ref={emblaRef}>
        <div className={styles.emblaContainer}>
          {storygalleryList.map((slide) => {
            return (
              <CarouselSlide key={slide.id}>
                <img src={slide.image} />
                <div className={`${styles.slideContent} slide-content`}>
                  <span className={styles.slideSubHeading}>{slide.caption}</span>
                </div>
              </CarouselSlide>
            );
          })}
        </div>
      </div>
      <div className={`${styles.emblaBtn} ${styles.emblaPrev}`} onClick={scrollPrev}>
        <LeftArrow className={styles.emblaBtnImg} width={50} height={50} />
      </div>
      <div className={`${styles.emblaBtn} ${styles.emblaNext}`} onClick={scrollNext}>
        <RightArrow className={styles.emblaBtnImg} width={50} height={50} />
      </div>
    </div>
  );
};

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

export default ImgGallery;

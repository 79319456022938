import styled from 'styled-components';

const TilesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const TilesImageWrapper = styled.div`
  position: relative;
  width: 35%;
  order: ${(props) => (props.imgSide === 'left' ? 1 : 2)};

  @media screen and (max-width: 860px) {
    width: 100%;
    order: 1;
  }
`;

const TilesImage = styled.img`
  width: 100%;
`;

const ImageCaption = styled.div`
  position: absolute;
    bottom: 0px;
    background: rgb(255 255 255 / 70%);
    color: #0C2340;
    padding: 5px 10px;
    font-size: 12px;
    width: 100%;
`;

const TilesContent = styled.div`
  padding: 40px 0;
  width: 65%;
  background-color: ${(props) => props.bgColor};
  display: flex;
  justify-content: center;
  align-items: center;
  order: ${(props) => (props.imgSide === 'left' ? 2 : 1)};

  @media screen and (max-width: 860px) {
    width: 100%;
    padding: 40px 0;
    order: 2;
  }
`;

const TilesContentInner = styled.div`
  width: 70%;
  color: ${(props) => props.color};

  @media screen and (max-width: 1275px) {
    width: 80%;
  }

  @media screen and (max-width: 860px) {
    width: 90%;
  }

  & h2 {
    font-size: 54px;
    width: ${(props) =>
      props.narrowText ? '35%' : props.titleLine ? '50%' : '100%'};

    position: relative;
    font-weight: bolder;

    @media screen and (max-width: 1275px) {
      width: 100%;
      font-size: 32px;
    }
  }

  & p {
    font-size: 20px;

    @media screen and (max-width: 1275px) {
      font-size: 16px;
    }
  }
`;

const LogoTileWrapper = styled.div`
  position: relative;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 200px 0;
  order: ${(props) => (props.imgSide === 'left' ? 1 : 2)};

  @media screen and (max-width: 1275px) {
    padding: 80px 0;
  }

  @media screen and (max-width: 860px) {
    width: 100%;
    order: 1;
    padding: 20px 0;
  }
`;

const LogoTileImage = styled.img`
  width: 65%;
  position: absolute;
  left: 34%;

  @media screen and (max-width: 1275px) {
    left: 24%;
  }

  @media screen and (max-width: 860px) {
    width: 50%;
    order: 1;
    position: relative;
    left: auto;
  }
`;

const WhoWeAreTiles = (props) => {
  let bgColor = 'white';
  let color = 'inherit';

  if (props.tileColor === 'blue') {
    bgColor = 'var(--dark-blue-color)';
    color = 'rgb(255, 255, 255)';
  }

  return (
    <TilesWrapper>
      {props.logoTile ? (
        <LogoTileWrapper imgSide={props.imgSide}>
          <LogoTileImage src={props.image} />
          {props.caption && <ImageCaption>{props.caption}</ImageCaption>}
        </LogoTileWrapper>
      ) : (
        <TilesImageWrapper imgSide={props.imgSide}>
          <TilesImage src={props.image} />
          {props.caption && <ImageCaption>{props.caption}</ImageCaption>}
        </TilesImageWrapper>
      )}
      <TilesContent bgColor={bgColor} imgSide={props.imgSide}>
        <TilesContentInner
          color={color}
          titleLine={props.titleLine}
          narrowText={props.narrowText}
        >
          {props.children}
        </TilesContentInner>
      </TilesContent>
    </TilesWrapper>
  );
};

export default WhoWeAreTiles;

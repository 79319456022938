import { Helmet } from 'react-helmet';
import styles from '../styles/Home.module.scss';
import BannerImage from '../components/BannerImage';
import Carousel from '../components/Carousel';
import Button from '../components/Button';
import Counter from '../components/Counter';
import homeTile1 from '../assets/images/tile-images/home-tile-1.png';
// import homeTile2 from '../assets/images/tile-images/home-tile-2.png';
import homeTile3 from '../assets/images/tile-images/year-in-review.jpg';
import { homeBannerData } from '../utils/bannerData/bannerData';
import S3CLink from '../components/S3CLink';

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Strengthening Systems for Safer Childbirth</title>
      </Helmet>
      <BannerImage data={homeBannerData} />
      <div className={styles.homeStatBar}>
        <div className={styles.homeStat}>
          <p className={styles.homeStatNumber}>
            <Counter number={4} />
          </p>
          <p className={styles.homeStatText}>Countries Engaged</p>
        </div>
        <div className={styles.homeStat}>
          <p className={styles.homeStatNumber}>
            <Counter number={20} />
          </p>
          <p className={styles.homeStatText}>Collaborators Activated</p>
        </div>
        <div className={styles.homeStat}>
          <p className={styles.homeStatNumber}>
            $<Counter number={25} />M
          </p>
          <p className={styles.homeStatText}>Committed</p>
        </div>
				<div className={styles.homeStat}>
          <p className={styles.homeStatNumber}>
            <Counter number={1} />M+
          </p>
          <p className={styles.homeStatText}>women to be reached by 2025</p>
        </div>
      </div>

      {/* <div className={`${styles.homeTileSection} ${styles.blueBg} ${styles.whiteColor}`}> */}
      {/* <div className={styles.homeTileSection}>
        <div className={`${styles.homeTileLarge} ${styles.homeTextTile}`}>
          <div className={styles.homeTileContent}>
            <h2 className={styles.homeTileTitle}>
            Year in review
            </h2>
            <p className={styles.homeTileBody}>
            One year ago, MSD for Mothers (MfM) announced a bold new $25M initiative, Strengthening Systems for Safer Childbirth, that set out to pick up the pace in saving women’s lives and achieve Sustainable Development Goals targets to reduce maternal mortality. To accelerate action, MfM focused on fostering community led solutions tapping into underutilized private sector innovations. Six coalitions representing twenty collaborators across four countries - India, Nigeria, Kenya and Sierra Leone - came on board to implement solutions based on the needs and preferences of the women in their communities. 
            </p>
           
            <S3CLink href='/what-we-do' internal>
              <Button variant='primary'>LEARN MORE</Button>
            </S3CLink>
          </div>
        </div>
        <div className={styles.homeTileSmall}>
          <img
            className={styles.homeTileImage}
            src={homeTile1}
            alt='Woman carrying sleeping baby on back.'
          />
        </div>
      </div> */}

      <div className={styles.homeTileSection}>
        <div
          className={`${styles.homeTileSmall} ${styles.homeTextTile} ${styles.homeTileSwitch}`}
        >
          <div className={styles.homeTileContent}>
            <h2 className={styles.homeTileTitle}>Year in review</h2>
            <p className={styles.homeTileBody}>
            One year ago, MSD for Mothers (MfM) announced a bold new $25M initiative, <S3CLink
            href='https://www.msdformothers.com/strengthening-systems-for-safer-childbirth.html'
            target='_blank'
          >
            <a>Strengthening Systems for Safer Childbirth</a>
          </S3CLink>, that set out to pick up the pace in saving women’s lives and achieve Sustainable Development Goals targets to reduce maternal mortality. To accelerate action, MfM focused on fostering community led solutions tapping into underutilized private sector innovations. Six coalitions representing twenty collaborators across four countries - India, Nigeria, Kenya and Sierra Leone - came on board to implement solutions based on the needs and preferences of the women in their communities. 
            </p>
            <S3CLink href='/year-in-review' internal>
              <Button variant='primary'>LEARN MORE</Button>
            </S3CLink>
          </div>
        </div>
        <div className={`${styles.homeTileLarge} ${styles.homeTileSwitch}`}>
          <img
            className={styles.homeTileImage}
            src={homeTile3}
            alt='Woman carrying baby on back.'
          />
        </div>
      </div>

      {/* <div className={`${styles.homeTileSection} ${styles.blueBg} ${styles.whiteColor}`}> */}
      <div className={styles.homeTileSection}>
        <div className={styles.homeTileSmall}>
          <img
            className={styles.homeTileImage}
            src={homeTile1}
            alt='Woman carrying sleeping baby on back.'
          />
        </div>
        <div className={`${styles.homeTileLarge} ${styles.homeTextTile}`}>
          <div className={styles.homeTileContent}>
            <h2 className={styles.homeTileTitle}>
              Think Globally, Help Save Mothers Locally
            </h2>
            <p className={styles.homeTileBody}>
              Strengthening Systems for Safer Childbirth is a new global
              initiative using locally-driven solutions to accelerate progress
              in helping save women’s lives. MSD for Mothers, working together
              with others, is supporting local coalitions to overcome the
              specific maternal health challenges in their communities, helping
              women have access to care to support healthier pregnancies and
              safer deliveries.
            </p>
            <S3CLink href='/what-we-do' internal>
              <Button variant='primary'>LEARN MORE</Button>
              {/* <Button variant='primaryWhiteColor'>LEARN MORE</Button> */}
            </S3CLink>
          </div>
        </div>
      </div>

      
      <Carousel />
    </>
  );
};

export default Home;

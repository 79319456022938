import { useContext } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import styles from '../styles/Navbar.module.scss';
import { Link } from 'react-router-dom';
import { ReactComponent as S3cLogo } from '../assets/images/logos/s3c-logo-white.svg';
import PageContext from '../utils/PageContext';

const MainNav = () => {
  const { currentPage, setCurrentPage } = useContext(PageContext);

  return (
    <Navbar
      collapseOnSelect
      expand='lg'
      bg='dark'
      variant='dark'
      style={{
        height: '105px',
        backgroundColor: 'var(--dark-blue-color) !important',
        fontSize: '18px'
      }}
    >
      <Container>
        <Navbar.Brand style={{ width: '215px' }}>
          <Nav.Link
            eventKey='0'
            as={Link}
            to='/'
            onClick={() => setCurrentPage('/')}
          >
            <S3cLogo className={styles.navbarBrand} />
          </Nav.Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
        <Navbar.Collapse
          id='responsive-navbar-nav'
          className='justify-content-end'
        >
          <Nav className='justify-content-end'>
            <Nav.Link
              eventKey='1'
              as={Link}
              to='/who-we-are'
              className={`${styles.navLink} ${
                currentPage === '/who-we-are' && `${styles.active}`
              }`}
              onClick={() => setCurrentPage('/who-we-are')}
              // data-bs-toggle='collapse'
              // data-bs-target='#responsive-navbar-nav'
            >
              Who We Are
            </Nav.Link>

            <Nav.Link
              eventKey='2'
              as={Link}
              to='/what-we-do'
              className={`${styles.navLink} ${
                currentPage === '/what-we-do' && `${styles.active}`
              }`}
              onClick={() => setCurrentPage('/what-we-do')}
            >
              What We Do
            </Nav.Link>
            <Nav.Link
              eventKey='3'
              as={Link}
              to='/where-we-work'
              className={`${styles.navLink} ${
                currentPage === '/where-we-work' && `${styles.active}`
              }`}
              onClick={() => setCurrentPage('/where-we-work')}
            >
              Where We Work
            </Nav.Link>
            <Nav.Link
              eventKey='4'
              as={Link}
              to='/insights'
              className={`${styles.navLink} ${
                currentPage === '/insights' && `${styles.active}`
              }`}
              onClick={() => setCurrentPage('/insights')}
            >
              Insights
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default MainNav;

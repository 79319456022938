import styled from 'styled-components';
import buttonVariants from '../utils/buttonVariants';

const ButtonStyle = styled.span`
  border: 2px solid ${(props) => props.variant.borderColor};
  padding: 8px 40px 5px 40px;
  border-radius: 50px;
  background-color: ${(props) => props.variant.backgroundColor};
  transition-duration: 0.3s;
  font-family: Invention, sans-serif;
  color: ${(props) => props.variant.color};
  display: inline-block;
  font-size: var(--body-font-size) !important;

  &:hover {
    border: 2px solid ${(props) => props.variant.hoverBorderColor};
    background-color: ${(props) => props.variant.hoverBackgroundColor};
    color: ${(props) => props.variant.hoverColor} !important;
    cursor: pointer;
  }
`;

const Button = (props) => {
  return (
    <ButtonStyle
      variant={buttonVariants[props.variant]}
      style={{ ...props.style }}
    >
      {props.children}
    </ButtonStyle>
  );
};

export default Button;

import { Helmet } from 'react-helmet';
import styles from '../styles/NewsAndMedia.module.scss';
import Button from '../components/Button';
import newsList from '../news/newsList';
import NewsThumbnail from '../components/News/NewsThumbnail';
import mfmLogo from '../assets/images/logos/msd-for-mothers-logo.png';

const NewsAndMedia = () => {
  return (
    <>
      <Helmet>
        <title>News & Media | Strengthening Systems for Safer Childbirth</title>
      </Helmet>
      <div className={styles.newsPageWrapper}>
        <div className={styles.newsHeader}>
          <h1>News & Media</h1>
          <p>
            Read the latest about how Strengthening Systems for Safer Childbirth
            is supporting local coalitions to accelerate progress to meet the
            Sustainable Development Goal (SDG) maternal mortality target.
          </p>
        </div>
        <div className={styles.newsTopSection}>
          <div className={styles.featuredNews}>
            <NewsThumbnail
              article={newsList[0]}
              featured
              external
              link={newsList[0].link}
            />
          </div>
          <div className={styles.newsTopGrid}>
            <NewsThumbnail article={newsList[1]} />
            <NewsThumbnail article={newsList[2]} />
            <NewsThumbnail article={newsList[3]} />
            <NewsThumbnail article={newsList[4]} />
          </div>
        </div>
        {/* <div className={styles.newsMidSection}>
          <div className={styles.newsHeader}>
            <h1>The Latest</h1>
          </div>
          <div className={styles.newsMidSectionGrid}>
            <NewsThumbnail
              article={newsList[5]}
              bottom
              buttonText='READ MORE'
            />
            <NewsThumbnail
              article={newsList[6]}
              bottom
              buttonText='READ MORE'
            />
            <NewsThumbnail
              article={newsList[7]}
              bottom
              buttonText='READ MORE'
            />
          </div>
        </div> */}
        <div className={styles.newsBottomSection}>
          <div className={styles.newsBottomLeft}>
            <img src={mfmLogo} alt='MFM Logo' />
            <p>
              This program is supported by funding from MSD, through MSD for
              Mother, the company’s global initiative to create a world where no
              woman has to die while giving life. MSD for Mothers is an
              initiative of Merck & Co., Inc., Rahway, NJ, USA.
            </p>
            <Button
              variant='primary'
              href='https://www.msdformothers.com'
              target='_blank'
            >
              LEARN MORE
            </Button>
          </div>
          <div className={styles.newsBottomDivider}></div>
          <div className={styles.newsBottomRight}>
            <h3>Media Inquiries</h3>
            <p>
              For press and media inquiries, please contact{' '}
              <a href='mailto:mediarelations@msd.com'>mediarelations@msd.com</a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsAndMedia;

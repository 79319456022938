import { Helmet } from 'react-helmet';
import { useState, useRef } from 'react';
import BannerImage from '../components/BannerImage';
import WhereWeWorkTiles from '../components/WhereWeWork/WhereWeWorkTiles';
import WhereWeWorkExpand from '../components/WhereWeWork/WhereWeWorkExpand';
import { whereWeWorkBannerData } from '../utils/bannerData/bannerData.js';
import whereWeWorkCountries from '../utils/whereWeWorkCountries';
import indiaLottieData from '../utils/countryLottieData/india.json';
import kenyaLottieData from '../utils/countryLottieData/kenya.json';
import nigeriaLottieData from '../utils/countryLottieData/nigeria.json';
import sierraLeoneLottieData from '../utils/countryLottieData/sierraLeone.json';

const WhereWeWork = (props) => {
  const [indiaHeight, setIndiaHeight] = useState(0);
  const [kenyaFirstHeight, setKenyaFirstHeight] = useState(0);
  const [kenyaSecondHeight, setKenyaSecondHeight] = useState(0);
  const [nigeriaFirstHeight, setNigeriaFirstHeight] = useState(0);
  const [nigeriaSecondHeight, setNigeriaSecondHeight] = useState(0);
  const [sierraLeoneHeight, setSierraLeoneHeight] = useState(0);

  const indiaRef = useRef(null);
  const kenyaFirstRef = useRef(null);
  const kenyaSecondRef = useRef(null);
  const nigeriaFirstRef = useRef(null);
  const nigeriaSecondRef = useRef(null);
  const sierraLeoneRef = useRef(null);

  return (
    <>
      <Helmet>
        <title>
          Where We Work | Strengthening Systems for Safer Childbirth
        </title>
      </Helmet>
      <BannerImage data={whereWeWorkBannerData} />
      <WhereWeWorkTiles
        id='india'
        heading='India'
        lottieData={indiaLottieData}
        imageSide='right'
        setHeight={setIndiaHeight}
        expandRef={indiaRef}
      >
        In hard-to-reach areas of India, women struggle to access equitable,
        timely, respectful quality maternal health care. Our coalition in India
        is launching a women-centered digital marketplace to improve the
        coordination of care and equip women with the essential information they
        need for a safe maternal health journey.
      </WhereWeWorkTiles>
      <WhereWeWorkExpand
        country={whereWeWorkCountries[0]}
        height={indiaHeight}
        setHeight={setIndiaHeight}
        propRef={indiaRef}
      />
      <WhereWeWorkTiles
        id='kenya'
        heading='Kenya'
        lottieData={kenyaLottieData}
        imageSide='left'
        setHeight={setKenyaFirstHeight}
        expandRef={kenyaFirstRef}
      >
        Innovation is at the center of improving quality care in Kenya. Our
        coalitions are leveraging innovations to improve access to emergency
        transportation and ensure solutions are driven by patient-reported
        outcomes data.
      </WhereWeWorkTiles>
      <WhereWeWorkExpand
        country={whereWeWorkCountries[1].sections[0]}
        height={kenyaFirstHeight}
        setHeight={setKenyaFirstHeight}
        propRef={kenyaFirstRef}
      />
      <WhereWeWorkExpand
        country={whereWeWorkCountries[1].sections[1]}
        height={kenyaSecondHeight}
        setHeight={setKenyaSecondHeight}
        propRef={kenyaSecondRef}
      />
      <WhereWeWorkTiles
        id='nigeria'
        heading='Nigeria'
        lottieData={nigeriaLottieData}
        imageSide='right'
        setHeight={setNigeriaFirstHeight}
        expandRef={nigeriaFirstRef}
      >
        Nigeria has the largest number of maternal deaths globally, accounting
        for 20% of all maternal deaths. Our coalitions in Nigeria are improving
        quality care through community engagement and mobilizing financing for
        local health facilities.
      </WhereWeWorkTiles>
      <WhereWeWorkExpand
        country={whereWeWorkCountries[2].sections[0]}
        height={nigeriaFirstHeight}
        setHeight={setNigeriaFirstHeight}
        propRef={nigeriaFirstRef}
      />
      <WhereWeWorkExpand
        country={whereWeWorkCountries[2].sections[1]}
        height={nigeriaSecondHeight}
        setHeight={setNigeriaSecondHeight}
        propRef={nigeriaSecondRef}
      />
      <WhereWeWorkTiles
        id='sierraLeone'
        heading='Sierra Leone'
        lottieData={sierraLeoneLottieData}
        imageSide='left'
        setHeight={setSierraLeoneHeight}
        expandRef={sierraLeoneRef}
      >
        Sierra Leone has the third highest maternal mortality rate in the world
        – largely driven by fragmentation between different levels of the health
        system. Our coalition in Sierra Leone is integrating the formal and
        informal health care systems through a hub-and-spoke model.
      </WhereWeWorkTiles>
      <WhereWeWorkExpand
        country={whereWeWorkCountries[3]}
        height={sierraLeoneHeight}
        setHeight={setSierraLeoneHeight}
        propRef={sierraLeoneRef}
      />
    </>
  );
};

export default WhereWeWork;

import styled from 'styled-components';
import Button from '../Button';
import CountryTile from '../WhereWeWork/CountryTile';

const TilesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: column;
  width: 70%;
  margin: 70px auto;

  @media screen and (max-width: 1450px) {
    width: 80%;
  }

  @media screen and (max-width: 1245px) {
    width: 90%;
  }

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 860px) {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
`;

const ContentTile = styled.div`
  align-self: center;
  justify-self: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media screen and (max-width: 675px) {
    order: 2;
  }
`;

const InnerContentTile = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;

  @media screen and (max-width: 1450px) {
    width: 85%;
  }

  @media screen and (max-width: 1000px) {
    width: 90%;
  }

  h2 {
    font-size: 48px;
    font-weight: bolder;
  }

  p {
    font-size: 20px;
  }
`;

const ImageTile = styled.div`
  align-self: center;
  justify-self: ${(props) =>
    props.imageSide === 'left' ? 'flex-start' : 'center'};
  width: 80%;

  div[role='button']:hover {
    cursor: default;
  }

  @media screen and (max-width: 1100px) {
    width: 90%;
  }
`;

const WhereWeWorkTiles = (props) => {
  const scrollToExpand = () => {
    if (props.heading === 'Sierra Leone') {
      setTimeout(
        () => props.expandRef.current.scrollIntoView({ behavior: 'smooth' }),
        200
      );
    } else {
      props.expandRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <TilesWrapper id={props.id}>
      {props.imageSide === 'right' && (
        <ContentTile imageSide={props.imageSide}>
          <InnerContentTile>
            <h2>{props.heading}</h2>
            <p>{props.children}</p>
            <div
              onClick={() => {
                props.setHeight('auto');
                scrollToExpand();
              }}
            >
              <Button variant='primary'>READ MORE</Button>
            </div>
          </InnerContentTile>
        </ContentTile>
      )}

      <ImageTile imageSide={props.imageSide}>
        <CountryTile src={props.countryImage} data={props.lottieData} />
      </ImageTile>

      {props.imageSide === 'left' && (
        <ContentTile imageSide={props.imageSide}>
          <InnerContentTile>
            <h2>{props.heading}</h2>
            <p>{props.children}</p>
            <div
              onClick={() => {
                props.setHeight('auto');
                scrollToExpand();
              }}
            >
              <Button variant='primary'>READ MORE</Button>
            </div>
          </InnerContentTile>
        </ContentTile>
      )}
    </TilesWrapper>
  );
};

export default WhereWeWorkTiles;

import styled from "styled-components";
import AnimateHeight from "react-animate-height";
import CountryCompany from "./CountryCompany";
import { ReactComponent as CrossButton } from "../../assets/images/icons/white-cross.svg";
import S3CLink from "../S3CLink";
import Button from "../Button";

const ExpandWrapper = styled.div`
  position: relative;
  overflow-anchor: none;
`;
const ClosedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--dark-blue-color);
  color: rgb(255, 255, 255);
  align-items: center;
  justify-content: center;
  padding: 70px 100px;
  gap: 20px;
  flex: 1;
  border-bottom: 2px solid lightgray;

  @media screen and (max-width: 650px) {
    padding: 70px 40px;
  }

  .expandMainImage {
    width: 180px;

    @media only screen and (max-width: 650px) {
      width: 120px;
    }
  }

  .expandTealText {
    color: var(--teal-color);
    font-size: 24px;

    @media screen and (max-width: 650px) {
      font-size: 18px;
    }
  }

  .expandHeading {
    font-size: 32px;
    text-align: center;
    font-weight: bold;

    @media screen and (max-width: 650px) {
      font-size: 24px;
    }
  }

  .expandBody {
    font-size: 20px;
    text-align: center;

    @media screen and (max-width: 650px) {
      font-size: 16px;
    }
  }

  .expandDualColumns {
    display: flex;
    gap: 70px;
    flex-wrap: wrap;
    align-items: stretch;

    @media screen and (max-width: 1100px) {
      flex-direction: column;
    }

    .expandColumn {
      padding: 40px 0;
      flex: 1 1;
    }

    .expandDivider {
      width: 1px;
      border-right: 4px dotted var(--purple-color);

      @media screen and (max-width: 1100px) {
        height: 1px;
        width: 100%;
        border-bottom: 4px dotted var(--purple-color);
        left: 0;
        top: 46%;
      }
    }
  }
`;

const ExpandButton = styled.div`
  display: flex;
  background-color: var(--purple-color);
  height: 65px;
  width: 65px;
  border-radius: 50%;
  color: white;
  font-size: 50px;
  position: absolute;
  left: 50%;
  bottom: -32.5px;
  transform: translateX(-50%) ${(props) => (props["aria-expanded"] ? "rotate(45deg)" : "rotate(0)")};
  align-items: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;
  border: 3px solid var(--purple-color);
  transition-duration: 0.5s;
  z-index: 100;

  &:hover {
    background-color: rgb(150, 137, 247);
    border-color: rgb(150, 137, 247);
  }

  .crossButton {
    height: 35px;
    width: 35px;
  }
`;

const VideoButton = styled.a`
  background-color: var(--teal-color);
  border: var(--teal-color) 2px solid;
  padding: 10px 20px;
  border-radius: 30px;
  font-size: 16px;
  transition-duration: 0.3s;
  text-align: center;

  &:hover {
    background-color: transparent;
  }
`;

const OpenWrapper = styled.div`
  background-color: #eaeaea;
  overflow: hidden;
`;

const CountryExpandedWrapper = styled.div`
  margin: 0px auto 100px auto;

  .country-expand-companies {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
    width: 80%;
    margin: auto;
  }

  .country-expand-resources-header {
    text-align: center;
    margin-top: 50px;
  }

  .country-expand-resources {
    margin-top: 20px;
    gap: 20px;
    width: 900px;
    margin: 20px auto 20px auto;

    @media only screen and (max-width: 975px) {
      width: 90%;
    }

    .coalition-resources {
      margin: auto;
    }

    h4 {
      color: var(--teal-color);
    }

    ul {
      margin-bottom: 25px;

      li {
        span {
          word-wrap: break-word;
        }
      }
    }

    .external-link {
      color: var(--teal-color);

      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media screen and (max-width: 687px) {
    gap: 60px;
    padding-top: 50px;
  }
`;

const CountryExpanded = ({ country }) => {
  return (
    <>
      <CountryExpandedWrapper>
        <div class='country-expand-companies'>
          {country.companies.map((company) => (
            <CountryCompany key={company.name} logo={company.logo} companyName={company.name} href={company.href} />
          ))}
        </div>
        {country.resources && <h2 className='country-expand-resources-header'>Resources</h2>}
        <div className='country-expand-resources'>{country.resources && country.resources}</div>
      </CountryExpandedWrapper>
    </>
  );
};

const WhereWeWorkExpand = ({ country, height, setHeight, propRef }) => {
  // const scrollToExpand = () =>
  //   propRef.current.scrollIntoView({ behavior: 'smooth' });

  return (
    <>
      {country.heading === "Sierra Leone" ? (
        <ExpandWrapper>
          <ClosedWrapper>
            <h3 className='expandTealText'>{country.tealText}</h3>
            {country.columns === 1 ? (
              <>
                <h2 className='expandHeading'>{country.heading}</h2>
                <p className='expandBody'>{country.body}</p>
              </>
            ) : (
              <div className='expandDualColumns'>
                <div className='expandColumn'>
                  <h2 className='expandHeading'>{country.heading1}</h2>
                  <h2 className='expandBody'>{country.body1}</h2>
                </div>
                <div className='expandDivider' />
                <div className='expandColumn'>
                  <h2 className='expandHeading'>{country.heading2}</h2>
                  <h2 className='expandBody'>{country.body2}</h2>
                </div>
              </div>
            )}
            <ExpandButton
              aria-expanded={height !== 0}
              aria-controls='open-panel'
              ref={propRef}
              onClick={() => {
                setHeight(height === 0 ? "auto" : 0);
              }}
            >
              <CrossButton className='crossButton' />
            </ExpandButton>
          </ClosedWrapper>
          <AnimateHeight id='open-panel' duration={500} height={height}>
            <OpenWrapper>
              <CountryExpanded country={country} />
            </OpenWrapper>
          </AnimateHeight>
        </ExpandWrapper>
      ) : (
        <ExpandWrapper ref={propRef}>
          <ClosedWrapper>
            <h3 className='expandTealText'>{country.tealText}</h3>
            {country.image && <img className='expandMainImage' src={country.image} />}
            {country.columns === 1 ? (
              <>
                <h2 className='expandHeading'>{country.heading}</h2>
                <p className='expandBody'>{country.body}</p>
              </>
            ) : (
              <div className='expandDualColumns'>
                <div className='expandColumn'>
                  <h2 className='expandHeading'>{country.heading1}</h2>
                  <h2 className='expandBody'>{country.body1}</h2>
                </div>
                <div className='expandDivider' />
                <div className='expandColumn'>
                  <h2 className='expandHeading'>{country.heading2}</h2>
                  <h2 className='expandBody'>{country.body2}</h2>
                </div>
              </div>
            )}
            <S3CLink href={country.videoLink} disclaimer>
              <VideoButton>Hear a word from {country.coalitionName || country.heading}</VideoButton>
            </S3CLink>
            <ExpandButton
              aria-expanded={height !== 0}
              aria-controls='open-panel'
              onClick={() => {
                setHeight(height === 0 ? "auto" : 0);
              }}
            >
              <CrossButton className='crossButton' />
            </ExpandButton>
          </ClosedWrapper>
          <AnimateHeight id='open-panel' duration={500} height={height}>
            <OpenWrapper>
              <CountryExpanded country={country} />
            </OpenWrapper>
          </AnimateHeight>
        </ExpandWrapper>
      )}
    </>
  );
};

export default WhereWeWorkExpand;

import { Helmet } from 'react-helmet';
import BannerImage from '../components/BannerImage';
import Button from '../components/Button';
import WhoWeAreTiles from '../components/WhoWeAreTiles';
import S3CLink from '../components/S3CLink';
import styles from '../styles/WhoWeAre.module.scss';
import tile1Img from '../assets/images/tile-images/who-we-are-tile-1.png';
import tile2Img from '../assets/images/tile-images/who-we-are-tile-2.png';
import mfmLogo from '../assets/images/logos/msd-for-mothers-logo.png';
import { whoWeAreBannerData } from '../utils/bannerData/bannerData.js';

const WhoWeAre = () => {
  return (
    <>
      <Helmet>
        <title>Who We Are | Strengthening Systems for Safer Childbirth</title>
      </Helmet>
      <BannerImage data={whoWeAreBannerData} />
      <div className={styles.whiteCopySection}>
        <strong>Strenghtening Systems for Safer Childbirth</strong> responds to
        the urgent need to pick up the pace in saving women’s lives and meet the
        Sustainable Development Goals for reducing maternal mortality. We
        believe that communities know best what’s needed to make the difference
        and that solutions are strongest when we can work across silos and
        sectors. Our goal is to strengthen locally-led, multi-sector coalitions,
        enabling them to implement solutions that will improve access to
        high-quality, respectful maternal health — for lasting impact.
      </div>

      <WhoWeAreTiles tileColor='blue' image={tile1Img} imgSide='left' titleLine>
        <h2>A Focused Approach</h2>
        <p>
          Coalition members are organizations that span sectors and disciplines
          – from private health care providers to community-based organizations
          to local entrepreneurs who bring innovation to service delivery.
          Working collectively, these organizations will design solutions based
          on the needs and preferences of women in their communities, striving
          to achieve sustained measurable improvements in maternal health.
        </p>
      </WhoWeAreTiles>

      <WhoWeAreTiles tileColor='white' image={mfmLogo} imgSide='left' logoTile>
        <h2>Powered by MSD for Mothers</h2>
        <p>
          MSD for Mothers is MSD’s global initiative to help create a world
          where no woman has to die while giving life. MSD for Mothers works
          with grantees and collaborators to improve the health and well-being
          of women during pregnancy, childbirth and the months after. MSD for
          Mothers is an initiative of Merck & Co., Inc.,
          Rahway,&nbsp;NJ,&nbsp;USA.
        </p>
        <S3CLink href='http://www.msdformothers.com'>
          <Button variant={'primary'}>LEARN MORE</Button>
        </S3CLink>
      </WhoWeAreTiles>
      <WhoWeAreTiles
        tileColor='blue'
        image={tile2Img}
        imgSide='right'
        titleLine
        narrowText
      >
        <h2>Our Coalitions</h2>
        <p>
          Strengthening Systems for Safer Childbirth supports six locally-led
          coalitions across India, Kenya, Nigeria and Sierra Leone aimed at
          improving maternal health care in their communities.
        </p>
        <S3CLink href='/where-we-work' internal>
          <Button variant={'primaryWhiteColor'}>LEARN MORE</Button>
        </S3CLink>
      </WhoWeAreTiles>
    </>
  );
};

export default WhoWeAre;

// import logoPlaceholder from '../assets/images/logos/logo-placeholder.png';
import niviIndiaLogo from "../assets/images/where-we-work-logos/nivi-india.png";
import sitiramInstituteLogo from "../assets/images/where-we-work-logos/sitaram-institute-logo.jpeg";
// import federationObstetricsLogo from "../assets/images/where-we-work-logos/federation-obstetrics-logo.png";
import jacarandaHealthLogo from "../assets/images/where-we-work-logos/jacaranda-health-logo.png";
import thinkWellLogo from "../assets/images/where-we-work-logos/think-well-logo.png";
import rescueCoLogo from "../assets/images/where-we-work-logos/rescue-co-logo.png";
// import amrefHealthLogo from '../assets/images/where-we-work-logos/amref-health-logo.jpeg';
import heliumHealthLogo from "../assets/images/where-we-work-logos/helium-health-logo.svg";
import thinkPLaceLogo from "../assets/images/where-we-work-logos/think-place-logo.jpg";
import scidarLogo from "../assets/images/where-we-work-logos/scidar-logo.png";
import healthStrategyLogo from "../assets/images/where-we-work-logos/health-strategy-logo.png";
import ingressLogo from "../assets/images/where-we-work-logos/ingress-logo.png";
import ihiLogo from "../assets/images/where-we-work-logos/ihi-logo.png";
import partnersInHealthLogo from "../assets/images/where-we-work-logos/partners-in-health-logo.png";
// import neoNatalLogo from '../assets/images/where-we-work-logos/neonatal-rescue-logo.png';
import lifebankLogo from "../assets/images/where-we-work-logos/lifebank-logo.jpg";
import janitriLogo from "../assets/images/where-we-work-logos/janitri-logo.png";
import popCouncilLogo from "../assets/images/where-we-work-logos/pop-council-logo.jpg";
import cnesLogo from "../assets/images/where-we-work-logos/cnes-logo.jpg";
import mdocLogo from "../assets/images/where-we-work-logos/mdoc-logo.png";
import afridaLogo from "../assets/images/where-we-work-logos/afrida-logo.png";
import sccanLogo from "../assets/images/where-we-work-logos/sccan-logo.png";
import gogsLogo from "../assets/images/where-we-work-logos/gogs-logo.jpg";

import forMomLogo from "../assets/images/where-we-work-logos/for-mom-logo.png";

import ESaathiResources from "../components/WhereWeWork/coalitionResources/ESaathiResources";
import WellbodyResources from "../components/WhereWeWork/coalitionResources/WellbodyResources";
import ProjectAishaResources from "../components/WhereWeWork/coalitionResources/ProjectAishaResources";

const whereWeWorkCountries = [
  {
    name: "india",
    columns: 1,
    heading: "e-SAATHI",
    body: "A Chat-Based Decision Support System for Pregnant Women and Providers in Assam, India to build partnerships based on a proven digital solution for promoting women’s autonomy over their reproductive health seeking behaviors, enabling self-care, and facilitating access to quality MH information and services across the pregnancy-postpartum continuum; thereby to contribute to the reduction in maternal and early neonatal morbidity and mortality.",
    videoLink: "https://www.youtube.com/watch?v=IMcfwREEu7o",
    tealText: "COALITION",
    resources: ESaathiResources,
    companies: [
      {
        logo: popCouncilLogo,
        name: "Population Council Consulting",
        href: "https://popcouncilconsulting.com/"
      },
      {
        logo: niviIndiaLogo,
        name: "Nivi India",
        href: "https://www.asknivi.in/"
      },
      {
        logo: sitiramInstituteLogo,
        name: "Sitaram Bhartia Institute of Science and Research",
        href: "https://www.sitarambhartia.org/"
      },
      {
        logo: gogsLogo,
        name: "Guwahati Obstetric & Gynaecological Society (GoGS)",
        href: "https://gogsassam.org/"
      },
      {
        logo: cnesLogo,
        name: "Centre for North East Studies and Policy Research (CNES)",
        href: "https://c-nes.org/"
      }
    ]
  },
  {
    name: "kenya",
    sections: [
      {
        heading: "Kenya Quality Ecosystem",
        body: "A Quality Ecosystem that links equitable, evidence-based maternal and newborn health solutions with money to pay for them and data to align them with the needs of a mother at every touchpoint with the health system.",
        videoLink: "https://youtu.be/P72ZLUfSphk",
        columns: 1,
        tealText: "COALITION",
        companies: [
          {
            logo: jacarandaHealthLogo,
            name: "Jacaranda Health",
            href: "https://www.jacarandahealth.org/"
          },
          {
            logo: thinkWellLogo,
            name: "ThinkWell",
            href: "https://thinkwell.global/"
          },
          {
            logo: thinkPLaceLogo,
            name: "ThinkPlace",
            href: "https://www.thinkplaceglobal.com/"
          }
        ]
      },
      {
        heading: "MamaLink",
        body: "Deploying an emergency transportation hotline for pregnant women in Nairobi.",
        videoLink: "https://youtu.be/lQj8m0q4qQg",
        columns: 1,
        tealText: "COALITION",
        companies: [
          {
            logo: rescueCoLogo,
            name: "Rescue.co",
            href: "https://www.rescue.co/"
          },
          {
            logo: jacarandaHealthLogo,
            name: "Jacaranda Health",
            href: "https://www.jacarandahealth.org/"
          }
          // {
          //   logo: amrefHealthLogo,
          //   name: 'Amref Health Africa',
          //   href: 'https://amref.org/'
          // }
        ]
      }
    ],
    tealText: "COALITIONS"
  },
  {
    name: "nigeria",
    sections: [
      {
        coalitionName: "FOR M(om)",
        columns: 1,
        tealText: "COALITION",
        heading: "Financing and Operational Revitalization for Maternal Care - FOR M(om)",
        body: "Mobilizing capacity-building financing for public and private health facilities in Kano, Lagos and Delta States.",
        videoLink: "https://youtu.be/5HnrWQo2TH8",
        image: forMomLogo,
        companies: [
          {
            logo: heliumHealthLogo,
            name: "Helium Health",
            href: "https://heliumhealth.com/"
          },
          {
            logo: scidarLogo,
            name: "Solina Centre for International Development and Research (SCIDaR)",
            href: "https://scidar.org/"
          },
          {
            logo: afridaLogo,
            name: "Afrida"
          }
        ]
      },
      {
        heading: "Project Aisha",
        body: "Leveraging women’s voices to tackle barriers to safe childbirth.",
        videoLink: "https://youtu.be/IJ-PID7aluI",
        columns: 1,
        tealText: "COALITION",
        resources: ProjectAishaResources,
        companies: [
          {
            logo: healthStrategyLogo,
            name: "Health Strategy & Delivery Foundation",
            href: "http://hsdf.org.ng/"
          },
          {
            logo: ingressLogo,
            name: "Ingress Health Partners",
            href: "https://www.ingresshealthpartners.com/"
          },
          {
            logo: mdocLogo,
            name: "mDoc",
            href: "https://www.mymdoc.com/?gclid=Cj0KCQjw94WZBhDtARIsAKxWG-9j8Gnedhqea9gEK7lPrK6MQdPDwiqsRKmdZ_gjVVrYvl_wi7j48FUaAjyiEALw_wcB"
          },
          {
            logo: ihiLogo,
            name: "Institute for Healthcare Improvement (IHI)",
            href: "https://www.ihi.org/"
          }
        ]
      }
    ]
  },
  {
    name: "sierraLeone",
    coalitionName: "The Wellbody Hub-and-Spoke Model",
    columns: 1,
    heading: "The Wellbody Hub-and-Spoke Model: A Safe Journey to Motherhood in Kono District",
    body: "Integrating formal and informal health care systems through a hub-and-spoke model in Kono district.",
    videoLink: "https://youtu.be/vyIWRkb0qBg",
    resources: WellbodyResources,
    companies: [
      {
        logo: partnersInHealthLogo,
        name: "Partners In Health — Sierra Leone",
        href: "https://www.pih.org/country/sierra-leone"
      },
      {
        logo: sccanLogo,
        name: "Sickle Cell Carers Awareness Network (SCCAN)"
      },
      {
        logo: lifebankLogo,
        name: "Lifebank",
        href: "https://lifebankcares.com/"
      },
      {
        logo: janitriLogo,
        name: "Janitri",
        href: "https://janitri.in/"
      }
    ]
  }
];

export default whereWeWorkCountries;

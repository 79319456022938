import { useState } from 'react';
import Lottie from 'react-lottie';
import ScrollTrigger from 'react-scroll-trigger';

const CountryTile = (props) => {
  const [play, setPlay] = useState(false);

  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: props.data,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <ScrollTrigger onEnter={() => setPlay(true)} onExit={() => setPlay(false)}>
      <Lottie options={defaultOptions} isStopped={!play} />
    </ScrollTrigger>
  );
};

export default CountryTile;

import S3CLink from "../../S3CLink";

const WellbodyResources = (
  <div className='coalition-resources'>
    Watch one of the directors of coalition speak at the World Health Assembly on advancing equitable access to care. The presentation can be found on
    the{" "}
    <S3CLink disclaimer href='https://pages.devex.com/how_the_private_sector_can_advance_equitable_access_to_care.html'>
      Devex website
    </S3CLink>
    .
  </div>
);

export default WellbodyResources;

import styled from 'styled-components';

const TileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  padding: 40px 0;
  flex: 1;

  @media screen and (max-width: 800px) {
    width: 100%;
    padding: 0;
  }

  h2 {
    font-weight: bolder;
    font-size: 48px;

    @media screen and (max-width: 1000px) {
      font-size: 38px;
    }

    @media screen and (max-width: 600px) {
      font-size: 32px;
    }
  }

  p {
    font-size: 20px;

    a {
      color: var(--teal-color) !important;
      font-weight: bold !important;
    }

    @media screen and (max-width: 1000px) {
      font-size: 18px;
    }

    @media screen and (max-width: 600px) {
      font-size: 16px;
    }
  }
`;

const VideoTile = (props) => {
  return (
    <TileWrapper firstTile={props.firstTile}>
      <h3><strong>{props.heading}</strong></h3>
      <p>{props.children}</p>
      {/* <Link to={props.ctaLink ? props.ctaLink : '/'} onClick={props.click}>
        <Button variant='primary'>LEARN MORE</Button>
      </Link> */}
    </TileWrapper>
  );
};

export default VideoTile;

import gallery1Img from "../assets/images/insights-images/Mamalink_thumbnail_2.jpg";
import gallery2Img from "../assets/images/insights-images/Mamalink_thumbnail.jpg";

const galleryList = [
  {
    id: 0,
    src: require('../assets/videos/Mamalink_TX_22.mp4'),
    image: gallery1Img
  },
  {
    id: 1,
    src: require('../assets/videos/Mamalink_video.mp4'),
    image: gallery2Img
  }
];

export default galleryList;

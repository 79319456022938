import S3CLink from "../../S3CLink";

const ProjectAishaResources = (
  <div className='coalition-resources'>
    <ul>
      <li>
        <S3CLink
          disclaimer
          href='https://www.linkedin.com/posts/health-strategy-and-delivery-foundation_hsdf-participates-in-project-aishas-refresher-activity-7072202780953780224-2qaq/?utm_source=share&utm_medium=member_ios'
        >
          Project Aisha Refresher Training for Traditional Birth Attendants
        </S3CLink>
      </li>
      <li>
        <S3CLink
          disclaimer
          href='https://www.linkedin.com/posts/health-strategy-and-delivery-foundation_icp-training-activity-7067544287118245889-ItwV/?utm_source=share&utm_medium=member_ios'
        >
          Improvement Coach Program Training
        </S3CLink>
      </li>
      <li>
        <S3CLink
          disclaimer
          href='https://www.linkedin.com/posts/health-strategy-and-delivery-foundation_project-aisha-activity-7052629975677476864-Sh4P?utm_source=share&utm_medium=member_ios'
        >
          Quality Improvement Learning Session 1
        </S3CLink>
      </li>
      <li>
        <S3CLink
          disclaimer
          href='https://www.linkedin.com/posts/health-strategy-and-delivery-foundation_project-aisha-activity-6998284704433004544-AC6t/?utm_source=share&utm_medium=member_ios'
        >
          Project Aisha Introductory Meeting in Lagos State
        </S3CLink>
      </li>
      <li>
        <S3CLink disclaimer href='https://twitter.com/HSDF_NG'>
          HSDF Twitter
        </S3CLink>
      </li>
      <li>
        <S3CLink
          disclaimer
          href='https://hsdf.org.ng/ihi-hsdf-and-lagos-state-quality-improvement-qi-mentors-jointly-facilitate-the-maiden-edition-of-the-maternal-health-quality-improvement-collaborative-learning-session-on-project-aisha%EF%BF%BC/'
        >
          Quality Improvement Learning Session 1
        </S3CLink>
      </li>
      <li>
        <S3CLink
          disclaimer
          href='https://www.linkedin.com/posts/mymdoc_projectaisha-projectaisha-mdochealthcare-activity-7072563338689265664-jZMe/?utm_source=share&utm_medium=member_android'
        >
          The curriculum development workshop for the tele-education sessions to build the capacity of healthcare workers.
        </S3CLink>
      </li>
      <li>
        <S3CLink disclaimer href='https://www.youtube.com/watch?v=IXgUH6-p0Nw'>
          Project Aisha Tele-Education session
        </S3CLink>
      </li>
    </ul>
  </div>
);

export default ProjectAishaResources;

import { Helmet } from 'react-helmet';
import BannerImage from '../components/BannerImage';
import styles from '../styles/WhatWeDo.module.scss';
import WhatWeDoTile from '../components/WhatWeDoTile';
import bannerImg2 from '../assets/images/banner-images/what-we-do-banner-2.png';
import bannerImg3 from '../assets/images/banner-images/what-we-do-banner-3.png';
import { whatWeDoBannerData } from '../utils/bannerData/bannerData.js';
import S3CLink from '../components/S3CLink';

const WhatWeDo = ({ setCurrentPage }) => {
  return (
    <>
      <BannerImage data={whatWeDoBannerData} />
      <Helmet>
        <title>What We Do | Strengthening Systems for Safer Childbirth</title>
      </Helmet>
      <div className={styles.wwdTilesSection}>
        <WhatWeDoTile
          heading='Learning Together for Collective Impact'
          ctaLink='/learn-more'
          click={() => setCurrentPage('learnMore')}
        >
          A community of practice, facilitated by{' '}
          <S3CLink
            href='https://www.accenture.com/us-en/about/company/accenture-development-partnerships-index'
            disclaimer
            target='_blank'
          >
            <a>Accenture Development Partnerships</a>
          </S3CLink>
          , will bring coalitions together to share experiences, problem-solve
          collectively, deliver on intended results, demonstrate impact, achieve
          sustainability and amplify their learnings.{' '}
          <S3CLink href='https://rabinmartin.com/' disclaimer target='_blank'>
            <a>Rabin Martin</a>
          </S3CLink>
          , a team of global health impact advisors, provides strategic guidance
          and technical support to the six coalitions.
        </WhatWeDoTile>
        <div className={styles.wwdTilesDivider} />
        <WhatWeDoTile
          heading='Meaningful Integration of Women’s Voices'
          ctaLink='/learn-more'
          click={() => setCurrentPage('learnMore')}
        >
          Coalition solutions are informed by local women’s needs and
          preferences, which are systematically integrated throughout program
          design, implementation and evaluation via ongoing feedback loops.
        </WhatWeDoTile>
      </div>
      <img
        className={styles.wwdContentBanner}
        src={bannerImg2}
        alt='Women sitting down and reading.'
      />
      <div className={styles.wwdTilesSection}>
        <WhatWeDoTile
          heading='Leveraging the Private Sector'
          ctaLink='/learn-more'
          click={() => setCurrentPage('learnMore')}
        >
          Coalitions leverage the resources, expertise and innovation from the
          local private sector – from maternity care providers to local
          entrepreneurs who bring innovation to service delivery.
        </WhatWeDoTile>
        <div className={styles.wwdTilesDivider} />
        <WhatWeDoTile
          heading='Prioritizing Health Equity'
          ctaLink='/learn-more'
          click={() => setCurrentPage('learnMore')}
        >
          Coalition solutions advance health equity by improving access to
          high-quality maternal health care for underserved or underrepresented
          groups.
        </WhatWeDoTile>
      </div>
      <img
        className={styles.wwdContentBanner}
        src={bannerImg3}
        alt='Doctor walking with pregnant woman.'
      />
      <div className={styles.wwdTilesSection}>
        <WhatWeDoTile
          heading='Amplifying Local Innovations'
          ctaLink='/learn-more'
          click={() => setCurrentPage('learnMore')}
        >
          Coalitions scale new or existing locally-tailored technology to
          augment government capacity, introduce efficiencies and address local
          maternal health challenges.
        </WhatWeDoTile>
        <div className={styles.wwdTilesDivider} />
        <WhatWeDoTile
          heading='Financing and Affordability of Care'
          ctaLink='/learn-more'
          click={() => setCurrentPage('learnMore')}
        >
          Coalition members work collectively to define a plan for their
          solution’s financial and operational sustainability, ensuring
          communities have long-term access to high-quality maternal health
          care.
        </WhatWeDoTile>
      </div>
    </>
  );
};

export default WhatWeDo;

import { useCallback, useState, useEffect } from "react";
import useEmblaCarousel from "embla-carousel-react";
import styled from "styled-components";
import Button from "../components/Button";
import S3CLink from "./S3CLink";
import newsList from "../news/newsList";
import styles from "../styles/Carousel.module.scss";
import { ReactComponent as LeftArrow } from "../assets/images/icons/left-arrow.svg";
import { ReactComponent as RightArrow } from "../assets/images/icons/right-arrow.svg";

const CarouselSlide = styled.div`
  flex: 0 0 50%;
  background-size: cover;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-image: ${(props) => `url(${props.imgSrc})`};
  position: relative;
  transition-duration: 0.3s;

  &:hover {
    box-shadow: none;
  }

  @media screen and (max-width: 1270px) {
    flex: 0 0 100%;
    background-size: cover;
  }

  .slideOverlay {
    width: 100%;
    height: 100%;
    background-color: rgba(12, 35, 66, 0.7);
    transition-duration: 0.3s;

    &:hover {
      opacity: 0;
    }
  }
`;

const Carousel = (props) => {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const [emblaRef, emblaApi] = useEmblaCarousel({
    containScroll: "trimSnaps",
    slidesToScroll: windowSize.innerWidth > 1270 ? 2 : 1
  });

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  return (
    <div className={styles.embla}>
      <div className='embla__viewport' ref={emblaRef}>
        <div className={styles.emblaContainer}>
          {newsList.map((slide) => {
            return (
              <CarouselSlide imgSrc={slide.image} key={slide.id}>
                <div className='slideOverlay'></div>
                <div className={`${styles.slideContent} slide-content`}>
                  <h3 className={styles.slideHeading}>{slide.headline}</h3>
                  <p className={styles.slideSubHeading}>{slide.subHeadline}</p>
                  <S3CLink href={slide.href} disclaimer={slide.disclaimer} internal={slide.internal}>
                    <Button variant='primaryWhiteColor'>{slide.btnText ? slide.btnText : "READ MORE"}</Button>
                  </S3CLink>
                </div>
              </CarouselSlide>
            );
          })}
        </div>
      </div>
      <div className={`${styles.emblaBtn} ${styles.emblaPrev}`} onClick={scrollPrev}>
        <LeftArrow className={styles.emblaBtnImg} width={50} height={50} />
      </div>
      <div className={`${styles.emblaBtn} ${styles.emblaNext}`} onClick={scrollNext}>
        <RightArrow className={styles.emblaBtnImg} width={50} height={50} />
      </div>
    </div>
  );
};

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

export default Carousel;

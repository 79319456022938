import { Helmet } from 'react-helmet';
import BannerImage from '../components/BannerImage';
import Button from '../components/Button';
import InsightsTiles from '../components/InsightsTiles';
import S3CLink from '../components/S3CLink';
import styles from '../styles/insight.module.scss';
import tile1Img from '../assets/images/tile-images/insights-tile-1.png';
import tile2Img from '../assets/images/tile-images/insights-tile-2.png';
import tile3Img from '../assets/images/tile-images/insights-tile-3.png';
import tile4Img from '../assets/images/tile-images/insights-tile-4.png';
import { insightsBannerData } from '../utils/bannerData/bannerData.js';
import VideoTile from '../components/VideoTile';
import StoriesGallery from "../components/storiesGallery";
import VideoCarousel from '../components/VideoCarousel';

const Insights = () => {
  return (
    <>
      <Helmet>
        <title>Insights | Strengthening Systems for Safer Childbirth</title>
      </Helmet>
      <BannerImage data={insightsBannerData} />
      <div className={styles.whiteCopySection}>
      Dive deeper into the impact. Explore videos, stories, and articles showcasing the inspiring work of our coalitions and how they are making a difference on the ground. 
      </div>

      <div className={styles.wwdTilesSection}>
        <VideoTile
          heading='e-SAATHI'
        >
          Learn how <strong>Nivi</strong>, Inc., e-SAATHI collaborator, is changing how family planning and other health care services get delivered in India
          
          <div className={styles.videoContainer}>
          <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7139888415075946497?compact=1" frameborder="0" allowfullscreen="" title="Embedded post"></iframe>
          </div>
         
        </VideoTile>
        <div className={styles.wwdTilesDivider} />
        <VideoTile
          heading='Project Aisha '
        >
          Learn how <strong>Project Aisha</strong> is tackling maternal mortality in Nigeria by addressing delays in seeking and receiving care
          <div className={`${styles.videoContainer} ${styles.marginTopAisha}`}>
          <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7155154596221349888?compact=1" frameborder="0" allowfullscreen="" title="Embedded post"></iframe>
          </div>
          
        </VideoTile>
      </div>

      <div className={styles.wwdTilesSection}>
        <VideoTile
          heading='Wellbody'
        >
          Learn how the <strong>Wellbody</strong> Hub-and-Spoke Model works with traditional birth attendants to improve maternal health care in Sierra Leone 
          
          <div className={styles.videoContainer}>
          <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7176548074645778432?compact=1" frameborder="0" allowfullscreen="" title="Embedded post"></iframe>
          </div>
        </VideoTile>
        <div className={styles.wwdTilesDivider} />
        <VideoTile
          heading='MamaLink '
        >
          Learn how <strong>MamaLink</strong> is increasing fast and efficient access to maternal health services in Kenya

          {/* <VideoCarousel /> */}

          <div className={styles.videoContainer}>
          <iframe src="https://www.youtube.com/embed/6NFMtoj550g?si=60f4vI-1Ig1ll8bB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>

        </VideoTile>

      </div>    

      <div className={styles.wwdTilesSection}>
        <VideoTile
          heading='FOR M(om)'
        >
          Learn about how the <strong>FOR M(om)</strong> team is helping strengthen systems for safer childbirth in Nigeria
          
          <div className={`${styles.videoContainer} ${styles.marginTopAisha}`}>
          <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7209126587181498368?compact=1" frameborder="0" allowfullscreen="" title="Embedded post"></iframe>
          </div>
        </VideoTile>
        <div className={styles.wwdTilesDivider} />
        <VideoTile
          heading='Kenya Quality Ecosystem '
        >
          Learn how Kenya Quality Ecosystem is transforming Makueni County's Health Care System through partnership and collaboration

          <div className={styles.videoContainer}>
          <iframe src="https://www.youtube.com/embed/GjVbduYCIFo?si=UcDR9gYKSGGj0372" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
          
        </VideoTile>

      </div>   

      <div className={styles.whiteCopySection}>
      <h2 align="center">Stories from our coalitions:</h2><br/>
      <StoriesGallery />
      </div>

      <div className={styles.whiteCopySection}>
      <h2 align="center">More from the coalitions:</h2>
      </div>
      

      <InsightsTiles tileColor='blue' image={tile1Img} imgSide='left' titleLine 
      caption='Stakeholders and collaborators at the launch of the FOR M(om) programme in Lagos State Nigeria. Photo credit: Nigeria Health Watch'>
        <h3>Read about how <strong>FOR M(om)</strong> is strengthening health systems to improve maternal health care</h3>
        <S3CLink href='https://articles.nigeriahealthwatch.com/strengthening-health-systems-to-improve-maternal-health-care-the-for-mom-programme/'>
          <Button variant={'primaryWhiteColor'}>LEARN MORE</Button>
        </S3CLink>
       
      </InsightsTiles>

      <InsightsTiles
        tileColor='white'
        image={tile2Img}
        imgSide='right'
        titleLine
        caption='A Turkana motorcycle ambulance. Photo by: © UNFPA / Luis Tato '
      >
        <h3>Read about how <strong>MamaLink</strong> is getting pregnant women to hospitals when ambulances can't reach them</h3>
        
        <S3CLink href='https://www.devex.com/news/how-to-get-pregnant-women-to-hospitals-when-ambulances-can-t-reach-them-106329'>
          <Button variant={'primary'}>LEARN MORE</Button>
        </S3CLink>
      </InsightsTiles>

      <InsightsTiles tileColor='blue' image={tile3Img} imgSide='left' titleLine
       caption="A new mother in Kibera, Nairobi, uses Jacaranda Health's digital health tool PROMPTS to share feedback via SMS on her experiences of care. Photo credit: Jacaranda Health">
        <h3>Read <strong>Kenya Quality Ecosystem’s</strong> recommendation on three ways to amplify women’s voices for improved maternal health</h3>
        <S3CLink href='https://www.devex.com/news/opinion-3-ways-to-amplify-women-s-voices-for-improved-maternal-health-106244'>
          <Button variant={'primaryWhiteColor'}>LEARN MORE</Button>
        </S3CLink>
       
      </InsightsTiles>
      <InsightsTiles
        tileColor='white'
        image={tile4Img}
        imgSide='right'
        titleLine
        caption='A compassionate Traditional Birth Attendant guides a pregnant woman through a checkup at Sewafe Community Health Center, embracing the importance of facility-based clinical care. Photo by AbuBakarr Tappiah Sesay / Partners In Health.'
      >
        <h3>Read about how <strong>Wellbody</strong> is integrating traditional birth attendants for improved maternal health care in Sierra Leone</h3>
        
        <S3CLink href='https://articles.nigeriahealthwatch.com/integrating-traditional-birth-attendants-for-improved-maternal-healthcare-in-sierra-leone/'>
          <Button variant={'primary'}>LEARN MORE</Button>
        </S3CLink>
      </InsightsTiles>
    </>
  );
};

export default Insights;

import { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import ReactGA from 'react-ga4';
import Home from './pages/Home';
import WhoWeAre from './pages/WhoWeAre';
import WhatWeDo from './pages/WhatWeDo';
import WhereWeWork from './pages/WhereWeWork';
import NewsAndMedia from './pages/NewsAndMedia';
import YearInReview from './pages/YearInReview';
import Insights from './pages/Insights';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import './styles/variables.css';
import './styles/globals.scss';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import ScrollToTop from './helpers/ScrollToTop';
import NewsArticle from './pages/articles/NewsArticle';
import LinkDisclaimer from './components/LinkDisclaimer';
import DisclaimerContext from './utils/DisclaimerContext';
import PageContext from './utils/PageContext';

function App() {
  ReactGA.initialize('G-6M5LBH530R');
  ReactGA.send('pageview');

  const [currentPage, setCurrentPage] = useState('/');
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [disclaimerLink, setDisclaimerLink] = useState('');

  useEffect(() => {
    // Load the OneTrust cookie script
    const otScript = document.createElement('script');
    otScript.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
    otScript.type = 'text/javascript';
    otScript.charset = 'UTF-8';
    otScript.setAttribute('data-domain-script', '019135bb-abf7-7f51-b21f-fc87ff47e306');
    //staging cookie id
    // otScript.setAttribute('data-domain-script', '019159d2-145d-743f-b26e-e2f4456c7167-test');
    document.body.appendChild(otScript);

    // OptanonWrapper function to be triggered by the script
    window.OptanonWrapper = function() {
      // You can add any additional logic here if needed
    };

    return () => {
      document.body.removeChild(otScript);
    };
  }, []);

  return (
    <div className='app'>
      <PageContext.Provider value={{ currentPage, setCurrentPage }}>
        <DisclaimerContext.Provider
          value={{
            showDisclaimer,
            setShowDisclaimer,
            disclaimerLink,
            setDisclaimerLink
          }}
        >
          <Navbar />
          <LinkDisclaimer />
          <ScrollToTop>
            <Routes>
              <Route path='/' index element={<Home />} />
              <Route path='/who-we-are' element={<WhoWeAre />} />
              <Route path='/what-we-do' element={<WhatWeDo />} />
              <Route path='/where-we-work' element={<WhereWeWork />} />
              <Route path='/insights' element={<Insights />} />
              <Route path='/learn-more' element={<NewsAndMedia />} />
              <Route path='/year-in-review' element={<YearInReview />} />
              <Route
                path='/learn-more/lorem-ipsum-dolor-sit-amet'
                element={<NewsArticle />}
              />
            </Routes>
          </ScrollToTop>
          <Footer />
        </DisclaimerContext.Provider>
      </PageContext.Provider>
    </div>
  );
}

export default App;

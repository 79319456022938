import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Button from '../Button';

const ThumbnailWrapper = styled.div`
  height: ${(props) =>
    props.featured ? '550px' : props.bottom ? '350px' : '100%'};
  background-image: ${(props) => `url('${props.image}')`};
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  transition-duration: 0.3s;
  ${(props) =>
    !props.featured && `box-shadow: inset 0 0 0 1000px rgba(12, 35, 66, 0.7);`}

  &:hover {
    box-shadow: none;
  }

  @media screen and (max-width: 1375px) {
    height: ${(props) => !props.featured && '290px'};
  }

  @media screen and (max-width: 1200px) {
    height: ${(props) => (props.featured ? '450px' : '290px')};
  }

  @media screen and (max-width: 500px) {
    height: 250px;
  }

  .thumbnailContent {
    position: absolute;
    bottom: 30px;
    left: 30px;
    right: 30px;

    h2 {
      color: white;
      font-weight: bolder;
      font-size: ${(props) => (props.featured ? '32px' : '20px')};

      @media screen and (max-width: 500px) {
        font-size: 20px;
      }
    }

    p {
      color: white;
      font-size: ${(props) => (props.featured ? '20px' : '14px')};

      @media screen and (max-width: 500px) {
        font-size: 14px;
      }
    }
  }
`;

const NewsThumbnail = ({ article, featured, buttonText, bottom }) => {
  return (
    <ThumbnailWrapper image={article.image} featured={featured} bottom={bottom}>
      <div className='thumbnailContent'>
        <h2>{article.headline}</h2>
        <p>{article.subHeadline}</p>

        {article.external ? (
          <Button
            variant='primaryWhiteColor'
            style={{ fontSize: '16px' }}
            href={article.link}
            target='_blank'
          >
            {buttonText || 'READ MORE'}
          </Button>
        ) : (
          <Link to={article.link}>
            <Button variant='primaryWhiteColor' style={{ fontSize: '16px' }}>
              {buttonText || 'READ MORE'}
            </Button>
          </Link>
        )}
      </div>
    </ThumbnailWrapper>
  );
};

export default NewsThumbnail;

import homeBanner from '../../assets/images/banner-images/home-banner.jpg';
import whoWeAreBanner from '../../assets/images/banner-images/who-we-are-banner.jpg';
import whereWeWorkBanner from '../../assets/images/banner-images/where-we-work-banner.jpg';
import whatWeDoBanner from '../../assets/images/banner-images/what-we-do-banner.jpg';
import insightsBanner from '../../assets/images/banner-images/insights-banner.jpg';
import getBannerLottie from './getBannerLottie';

const homeBannerData = {
  content: {
    heading: 'Strengthening Systems for Safer Childbirth',
    subheading:
      'Strengthening local health systems through multi-sectoral collaboration'
  },
  position: {
    xxlTop: '519px',
    xxlLeft: '466px',
    xlTop: '458px',
    xlLeft: '334px',
    largeTop: '392px',
    largeLeft: '206px',
    medTop: '369px',
    medLeft: '226px',
    smallTop: '367px',
    smallLeft: '100px'
  },
  lottieData: getBannerLottie({
    bannerImage: homeBanner,
    positionX: 1996,
    positionY: 1096,
    direction: 'textLeft'
  }),
  mobileBanner: homeBanner
};

const whoWeAreBannerData = {
  content: {
    heading: 'Strengthening Systems for Safer Childbirth',
    subheading:
      'Strengthening local health systems through multi-sectoral collaboration',
    contentWidth: '450px'
  },
  position: {
    xxlTop: '510px',
    xxlLeft: '1054px',
    xlTop: '448px',
    xlLeft: '927px',
    largeTop: '387px',
    largeLeft: '798px',
    medTop: '362px',
    medLeft: '671px',
    smallTop: '360px',
    smallLeft: '544px'
  },
  lottieData: getBannerLottie({
    bannerImage: whoWeAreBanner,
    positionX: 1976,
    positionY: 1076,
    direction: 'textRight'
  }),
  mobileBanner: whoWeAreBanner
};

const whatWeDoBannerData = {
  content: {
    heading: 'What We Do',
    subheading:
      'Our approach prioritizes core principles that help coalitions develop and implement locally relevant solutions.',
    contentWidth: '450px'
  },
  position: {
    xxlTop: '510px',
    xxlLeft: '1028px',
    xlTop: '448px',
    xlLeft: '903px',
    largeTop: '387px',
    largeLeft: '778px',
    medTop: '362px',
    medLeft: '652px',
    smallTop: '360px',
    smallLeft: '528px'
  },
  lottieData: getBannerLottie({
    bannerImage: whatWeDoBanner,
    positionX: 1926,
    positionY: 1076,
    direction: 'textRight'
  }),
  mobileBanner: whatWeDoBanner
};

const whereWeWorkBannerData = {
  content: {
    heading: 'Where We Work',
    subheading: 'In 2022, we launched six coalitions coalitions in ',
    links: [
      { text: 'India', link: '#india' },
      { text: 'Nigeria', link: '#nigeria' },
      { text: 'Kenya', link: '#kenya' },
      { text: 'Sierra Leone', link: '#sierraLeone' }
    ]
  },
  position: {
    xxlTop: '511px',
    xxlLeft: '987px',
    xlTop: '448px',
    xlLeft: '865px',
    largeTop: '384px',
    largeLeft: '745px',
    medTop: '360px',
    medLeft: '623px',
    smallTop: '359px',
    smallLeft: '500px'
  },
  lottieData: getBannerLottie({
    bannerImage: whereWeWorkBanner,
    positionX: 1850,
    positionY: 1076,
    direction: 'textRight'
  }),
  mobileBanner: whereWeWorkBanner
};

const insightsBannerData = {
  content: {
    heading: 'Insights from our Coalitions',
    subheading:
      'Read about the work that our coalitions are doing on the frontlines of health'
  },
  position: {
    xxlTop: '519px',
    xxlLeft: '466px',
    xlTop: '458px',
    xlLeft: '334px',
    largeTop: '392px',
    largeLeft: '206px',
    medTop: '369px',
    medLeft: '226px',
    smallTop: '367px',
    smallLeft: '100px'
  },
  lottieData: getBannerLottie({
    bannerImage: insightsBanner,
    positionX: 1996,
    positionY: 1096,
    direction: 'textLeft'
  }),
  mobileBanner: insightsBanner
};


export {
  homeBannerData,
  whoWeAreBannerData,
  whatWeDoBannerData,
  whereWeWorkBannerData,
  insightsBannerData
};

import { useEffect, useContext } from 'react';
import styled from 'styled-components';
import DisclaimerContext from '../utils/DisclaimerContext';

const BackgroundBlur = styled.div`
  top: 0;
  left: 0;
  opacity: ${(props) => (props.show ? 0.5 : 0)};
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  width: 100vw;
  z-index: 999;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(50px);
  overflow-y: hidden;
`;

const Disclaimer = styled.div`
  opacity: ${(props) => (props.show ? 1 : 0)};
  display: ${(props) => (props.show ? 'block' : 'none')};
  width: 700px;
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 25px 25px 70px 25px;
  border-radius: 15px;
  z-index: 1000;
  transition: opacity 0.5s linear 1s;
  box-shadow: 0 0 10px 3px #8e8e8e;

  @media screen and (max-width: 775px) {
    width: 90%;
    height: auto;
  }

  .modalHeader {
    font-size: 24px;
    color: var(--dark-blue-color);
    text-align: left;
  }

  .modalBody {
    font-size: 16px;
    color: var(--dark-blue-color) !important;
    text-align: left;
  }

  .modalButton {
    position: absolute;
    bottom: 20px;
    padding: 10px 20px;
    border-radius: 10px;
    transition-duration: 0.3s;

    &.cancel {
      color: gray;
      right: 150px;
      margin-bottom: 0;
      cursor: pointer;
      border: 1px solid gray;

      &:hover {
        background-color: rgba(211, 211, 211, 0.5);
      }
    }

    &.continue {
      color: white !important;
      background-color: var(--teal-color);
      border-radius: 10px;
      right: 25px;
      border: 2px solid var(--teal-color) !important;

      &:hover {
        color: var(--teal-color) !important;
        background-color: transparent;
      }
    }
  }
`;

const LinkDisclaimer = () => {
  const { showDisclaimer, setShowDisclaimer, disclaimerLink } =
    useContext(DisclaimerContext);

  useEffect(() => {
    if (showDisclaimer === true) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  });

  return (
    <>
      <BackgroundBlur show={showDisclaimer} />
      <Disclaimer show={showDisclaimer}>
        <p className='modalHeader'>Confirmation</p>
        <p className='modalBody'>
          The link you have selected will take you to a site outside of
          strengtheningchildbirthsystems.com. MSD does not review or control any
          non-MSD site. MSD does not endorse and is not responsible for the
          accuracy, content, practices, or standards of any non-MSD site. MSD is
          known as Merck & Co., Inc, Rahway, NJ USA in the United States and
          Canada.
        </p>
        <p
          className='modalButton cancel'
          onClick={() => setShowDisclaimer(false)}
        >
          Cancel
        </p>
        <a
          className='modalButton continue'
          href={disclaimerLink}
          onClick={() => setShowDisclaimer(false)}
          target='_blank'
          rel='noreferrer'
        >
          Continue
        </a>
      </Disclaimer>
    </>
  );
};

export default LinkDisclaimer;

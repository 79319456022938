import article1Img from "../assets/images/news-images/article-1-img.jpg";
import article2Img from "../assets/images/news-images/article-2-img.jpg";
import article3Img from "../assets/images/news-images/article-3-img.png";
import article4Img from "../assets/images/carousel-images/carousel-slide-3.jpg";
import article5Img from "../assets/images/carousel-images/carousel-slide-4.jpg";
import article6Img from "../assets/images/carousel-images/carousel-slide-5.JPG";
import article7Img from "../assets/images/carousel-images/carousel-slide-6.JPG";
import article8Img from "../assets/images/carousel-images/carousel-slide-8.jpg";
import article9Img from "../assets/images/carousel-images/carousel-slide-9.jpg";


const newsList = [
  {
    id: 0,
    headline: "Strengthening health systems through innovative health financing mechanisms",
    subHeadline: "Read about how FOR M(om) is leveraging innovative healthcare financing solutions for maternal care in Nigeria ",
    image: article8Img,
    href: "https://articles.nigeriahealthwatch.com/strengthening-health-systems-to-improve-maternal-health-care-the-for-mom-programme/",
    external: true,
    disclaimer: true
  },
  {
    id: 1,
    headline: "Sustainably improving maternal services and systems through the meaningful integration of women’s voices ",
    subHeadline: "Kenya Quality Ecosystem shares three ways we see women’s voices driving a shift in uptake and quality of maternal health care",
    image: article9Img,
    href: "https://www.devex.com/news/opinion-3-ways-to-amplify-women-s-voices-for-improved-maternal-health-106244",
    external: true,
    disclaimer: true
  },
  {
    id: 2,
    headline: "A word from one of our coalitions at the World Health Assembly",
    subHeadline: "Watch Wellbody speak about how the private sector can advance equitable access to care",
    image: article4Img,
    href: "https://pages.devex.com/how_the_private_sector_can_advance_equitable_access_to_care.html",
    external: true,
    disclaimer: true
  },
  {
    id: 3,
    headline: "Strengthening Systems for Safer Childbirth through healthcare provider training",
    subHeadline: "Watch Project Aisha provide a tele-education session for healthcare workers to build patient-centered skills",
    image: article5Img,
    href: "https://www.youtube.com/watch?v=IXgUH6-p0Nw",
    external: true,
    disclaimer: true
  },
  {
    id: 4,
    headline: "Read a recent publication from one of our collaborators",
    subHeadline: "Read e-SAATHI’s recent publication on predicting healthcare-seeking behavior",
    image: article6Img,
    href: "https://academic.oup.com/tbm/article-abstract/12/1/ibab096/6324644?redirectedFrom=fulltextPredicting",
    external: true,
    disclaimer: true
  },
  {
    id: 5,
    headline: "Stay up to date with one of our collaborators",
    subHeadline: "Sign up for the Nivi newsletter, one e-SAATHI’s collaborators",
    image: article7Img,
    href: "https://nivi.substack.com/",
    external: true,
    disclaimer: true
  },
  {
    id: 6,
    headline: "Strengthening Systems for Safer Childbirth",
    subHeadline: "A call to action to accelerate progress in ending maternal mortality",
    image: article1Img,
    href: "https://www.merckformothers.com/strengthening-systems-for-safer-childbirth.html",
    external: true
  },
  {
    id: 7,
    headline: "A Word from our Coalitions",
    subHeadline: "Hear from the collaborators who shared what women in their communities need for better maternal health",
    image: article3Img,
    external: true,
    disclaimer: true,
    href: "https://www.youtube.com/watch?v=wdQlR9MK0Dw",
    btnText: "WATCH HERE"
  },
  {
    id: 8,
    headline: "Strengthening Systems for Safer Childbirth Through Locally-Led Solutions",
    subHeadline: "Watch the Devex and MSD for Mothers launch event",
    image: article2Img,
    external: true,
    disclaimer: true,
    href: "https://pages.devex.com/strengthening-systems-for-safer-childbirth-through-locally-led-solutions-in-person.html",
    btnText: "WATCH HERE"
  }
];

export default newsList;

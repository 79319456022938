const buttonVariants = {
  primary: {
    color: "rgb(12, 35, 66)",
    backgroundColor: "transparent",
    borderColor: "rgb(0, 133, 124)",
    hoverColor: "rgb(255, 255, 255)",
    hoverBackgroundColor: "rgb(0, 133, 124)",
    hoverBorderColor: "rgb(0, 133, 124)"
  },
  secondary: {
    color: "rgb(255, 255, 255)",
    backgroundColor: "rgb(72,96,124)",
    borderColor: "rgb(72,96,124)",
    hoverColor: "rgb(255, 255, 255)",
    hoverBackgroundColor: "rgb(0, 133, 124)",
    hoverBorderColor: "rgb(0, 133, 124)"
  },
  primaryWhiteColor: {
    color: "rgb(255, 255, 255) !important;",
    backgroundColor: "transparent",
    borderColor: "rgb(0, 133, 124)",
    hoverColor: "rgb(255, 255, 255)",
    hoverBackgroundColor: "rgb(0, 133, 124)",
    hoverBorderColor: "rgb(0, 133, 124)"
  },
  teal: {
    color: "rgb(255, 255, 255) !important;",
    backgroundColor: "rgb(0, 133, 124)",
    borderColor: "rgb(0, 133, 124)",
    hoverColor: "rgb(0, 133, 124)",
    hoverBackgroundColor: "transparent",
    hoverBorderColor: "rgb(0, 133, 124)"
  }
};

export default buttonVariants;

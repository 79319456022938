import gallery1Img from "../assets/images/gallery-images/story-gallery-img1.png";
import gallery2Img from "../assets/images/gallery-images/story-gallery-img2.jpg";
import gallery3Img from "../assets/images/gallery-images/story-gallery-img3.jpg";
import gallery4Img from "../assets/images/gallery-images/story-gallery-img4.png";
import gallery5Img from "../assets/images/gallery-images/story-gallery-img5.png";
import gallery6Img from "../assets/images/gallery-images/story-gallery-img6.jpg";
import S3CLink from "../components/S3CLink";

const galleryList = [
  {
    id: 0,
    caption: (
      <p>
        <span className='gallery-caption'>Photo: Engagement with Traditional Birth Attendants</span> 
        <strong className='gallery-coalition-title'>Wellbody (Sierra Leone)</strong>
        A 35-year-old pregnant woman living in the remote village of Kayima experienced a turbulent pregnancy despite regularly attending antenatal care appointments at the local Community Health Center. When she went into labor, she travelled over 10 kilometers on foot to seek care and upon arrival, received a rare diagnosis of hand prolapse. The midwife in charge called National Emergency Medical Services for an ambulance to transfer her to Koidu Government Hospital (KGH). At KGH, the patient underwent an emergency caesarean section, received two units of blood, and delivered a full-term newborn.  
        
      </p>
    ),
    image: gallery1Img
  },
  {
    id: 1,
    caption: (
      <p>
        <span className='gallery-caption'>Photo: Workers at the ambulance dispatch centre</span> 
        <strong className='gallery-coalition-title'>MamaLink (Kenya)</strong>
        Workers at the dispatch centre received a call pleading for urgent help for a mother who had lost a previous pregnancy and was struggling with drug addiction. The baby was at risk of being born with complications. Within 7 minutes, an ambulance was on site. Fortunately, the mother delivered a healthy baby girl before being transported by the ambulance team. Both were transferred to a higher-level hospital for further review and monitoring. After a few days of care, both the baby and the mother were determined to be healthy and were discharged from the hospital.
      </p>
    ),
    image: gallery2Img
  },
  {
    id: 2,
    caption: (
      <p>
        <span className='gallery-caption'>Photo: Women using Nivi chatbot</span> 
        <strong className='gallery-coalition-title'>e-SAATHI (India)</strong>
        Mothers feel that the Nivi chatbot offers comprehensive guidance throughout the pregnancy journey from conception to postpartum care. The mothers noted that “in the presence of multiple conflicting sources of information, the chatbot provides official advice that is empowering." The chatbot's tailored advice and recommendations on dietary matters has also proven valuable in preparing for pregnancy. They also appreciated the timely notification for what to expect in the coming week.
      </p>
    ),
    image: gallery3Img
  },
  {
    id: 3,
    caption: (
      <p>
        <span className='gallery-caption'>Photo: Health care Worker introducing PROMPTS to a patient </span> 
        <strong className='gallery-coalition-title'>Kenya Quality Ecosystem (Kenya)</strong>
        A pregnant woman signed up for Jacaranda’s digital health tool PROMPTS during a routine visit to the Makueni Sub County Referral Hospital. As a first-time mother, she was relieved to start receiving messages to guide her through her pregnancy journey – messages helping her eat a balanced diet and, importantly, reminders for when to visit a facility for her prenatal care check-ups. When her baby boy was born, she found PROMPTS carried her through the daunting stages of newborn care and breastfeeding and helped her navigate the nuanced world of family planning – something no one had spoken with her about previously.
      </p>
    ),
    image: gallery4Img
  },
  {
    id: 4,
    caption: (
      <p>
        <span className='gallery-caption'>Photo: Women using NaviHealth to share feedback </span> 
        <strong className='gallery-coalition-title'>Project Aisha (Nigeria)</strong>
        The use of NaviHealth to harness women’s experiences of care and utilizing their feedback to improve patient care is a priority for Project Aisha. A patient who visited a primary health care centre expressed her negative feedback with the management of her baby on the NaviHealth mobile application. A closer look into her feedback found that lack of information provided by health care workers and poor communication contributed towards the patient’s poor experience. While the child was managed in adherence to protocol as evident with the child’s improved health, the mother was not adequately informed. The mother appreciated the follow-up and committed to continued use of the feedback platform.
      </p>
    ),
    image: gallery5Img
  },
  {
    id: 5,
    caption: (
      <p>
        <span className='gallery-caption'>Photo: FOR M(om)’s community engagement efforts </span> 
        <strong className='gallery-coalition-title'>FOR M(om) (Nigeria)</strong>
        Before FOR M(om)'s community engagement efforts, women lacked essential health care knowledge and access in the rural community of Ikotun, Lagos State. Two pregnant women reported benefitting from information sessions and prenatal care at their local hospital. They went on to have healthy pregnancies, safe childbirths, and they each have thriving newborns now. FOR M(om)'s impact transformed their experiences and countless others, illustrating the tangible benefits of community engagement in maternal and child health.   
      </p>
    ),
    image: gallery6Img
  }
];

export default galleryList;

import { useEffect, useState } from "react";
import styled from "styled-components";
import Lottie from "react-lottie";

const BannerWrapper = styled.div`
  position: relative;
  height: 1025px;
  overflow: hidden;
  z-index: 1;

  @media screen and (max-width: 1800px) {
    height: 900px;
  }

  @media screen and (max-width: 1550px) {
    height: 775px;
  }

  @media screen and (max-width: 1350px) {
    height: 725px;
  }

  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

const LottieWrapper = styled.div`
  position: absolute;
  width: 2050px;
  height: 100%;

  animation-name: fadeIn;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;

  @keyframes fadeIn {
    from {
      opacity: 0.5;
    }

    to {
      opacity: 1;
    }
  }

  div[role="button"]:hover {
    cursor: default;
  }

  @media screen and (max-width: 1800px) {
    width: 1800px;
  }

  @media screen and (max-width: 1550px) {
    width: 1550px;
  }

  @media screen and (max-width: 1350px) {
    width: 1400px;
    left: -50px;
  }

  @media screen and (max-width: 1150px) {
    width: 1250px;
    left: -100px;
  }

  @media screen and (max-width: 1000px) {
    width: 100vw;
    position: relative;
    left: 0;
  }
`;

const BannerContent = styled.div`
  background-color: var(--teal-color);
  color: white;
  width: ${(props) => props.contentWidth};
  padding: 30px;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;

  top: ${(props) => props.xxlTop};
  left: ${(props) => props.xxlLeft};

  @media screen and (max-width: 1800px) {
    top: ${(props) => props.xlTop};
    left: ${(props) => props.xlLeft};
  }

  @media screen and (max-width: 1550px) {
    top: ${(props) => props.largeTop};
    left: ${(props) => props.largeLeft};
  }

  @media screen and (max-width: 1350px) {
    top: ${(props) => props.medTop};
    left: ${(props) => props.medLeft};
    width: 450px;
    height: auto;
    padding: 20px;
  }

  @media screen and (max-width: 1150px) {
    top: ${(props) => props.smallTop};
    left: ${(props) => props.smallLeft};
  }

  h1 {
    font-weight: bolder;
    font-size: 32px;
  }

  h2 {
    font-size: 24px;

    span a {
      text-decoration: underline !important;

      &:hover {
        text-decoration: none !important;
      }
    }
  }
`;

const MobileBannerWrapper = styled.div`
  display: none;
  z-index: 0;

  div {
    z-index: 0;
    width: 100%;
  }

  div div svg {
    z-index: 0;
  }

  @media screen and (max-width: 1000px) {
    display: block;
    width: 100%;
  }

  .mobileBannerContent {
    width: 100%;
    background-color: var(--teal-color);
    color: white;
    padding: 30px 20px;

    @media screen and (max-width: 450px) {
      padding: 15px;
    }

    h1 {
      font-size: 36px;
      text-align: center;
      font-weight: bolder;

      @media screen and (max-width: 450px) {
        font-size: 24px;
      }
    }

    h2 {
      font-size: 24px;
      text-align: center;
      margin: 0;

      span a {
        text-decoration: underline !important;
      }

      @media screen and (max-width: 450px) {
        font-size: 16px;
      }
    }
  }

  img {
    width: 100%;
    height: auto;
    margin: 0;
  }
`;

const BannerImage = ({ data }) => {
  const [play, setPlay] = useState(false);

  useEffect(() => {
    const onPageLoad = () => {
      setTimeout(() => setPlay(true), 500);
    };

    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);

      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);

  const {
    content: { heading, subheading, contentWidth, links },
    position: { xxlTop, xxlLeft, xlTop, xlLeft, largeTop, largeLeft, medTop, medLeft, smallTop, smallLeft },
    lottieData
  } = data;

  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: lottieData,
    duration: 4000,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    },
    isPaused: !play
  };

  return (
    <>
      <BannerWrapper>
        <LottieWrapper>
          <Lottie options={defaultOptions} />
        </LottieWrapper>
        <BannerContent
          xxlTop={xxlTop}
          xxlLeft={xxlLeft}
          xlTop={xlTop}
          xlLeft={xlLeft}
          largeTop={largeTop}
          largeLeft={largeLeft}
          medTop={medTop}
          medLeft={medLeft}
          smallTop={smallTop}
          smallLeft={smallLeft}
          contentWidth={contentWidth || "600px"}
        >
          <h1>{heading}</h1>
          <h2>
            {subheading}
            {links &&
              links.map((link, index) => {
                return (
                  <span key={index}>
                    <a href={link.link}>{link.text}</a>
                    {index + 1 === links.length ? "." : index + 1 === links.length - 1 ? ", and " : ", "}
                  </span>
                );
              })}
          </h2>
        </BannerContent>
      </BannerWrapper>
      <MobileBannerWrapper>
        <div className='mobileBannerContent'>
          <h1>{heading}</h1>
          <h2>
            {subheading}
            {links &&
              links.map((link, index) => {
                return (
                  <span key={index}>
                    <a href={link.link}>{link.text}</a>
                    {index + 1 === links.length ? "." : index + 1 === links.length - 1 ? ", and " : ", "}
                  </span>
                );
              })}
          </h2>
        </div>
        <LottieWrapper>
          <Lottie options={defaultOptions} />
        </LottieWrapper>
      </MobileBannerWrapper>
    </>
  );
};

export default BannerImage;

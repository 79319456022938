import { Helmet } from "react-helmet";
import styles from "../styles/YearInReview.module.scss";
import S3CLink from "../components/S3CLink";
import ImgGallery from "../components/ImgGallery";

const YearInReview = () => {
  return (
    <>
      <Helmet>
        <title>Year in Review | Strengthening Systems for Safer Childbirth</title>
      </Helmet>

      <div className={styles.PageWrapper}>
        <div className={styles.Header}>
          <h1>Year in Review</h1>
          <p>
            One year ago, MSD for Mothers announced a bold new $25M initiative,{" "}
            <S3CLink href='https://www.msdformothers.com/strengthening-systems-for-safer-childbirth.html' target='_blank'>
              <a>Strengthening Systems for Safer Childbirth</a>
            </S3CLink>
            , that set out to pick up the pace in saving women’s lives and achieve Sustainable Development Goals targets to reduce maternal mortality.
            To accelerate action, the initiative focused on fostering community led solutions tapping into underutilized private sector innovations.
            Six coalitions representing twenty collaborators across four countries - India, Nigeria, Kenya and Sierra Leone - came on board to
            implement solutions based on the needs and preferences of the women in their communities.
          </p>
          <p>
            We are proud to share that in just the first year alone, the coalitions have had remarkable achievements that are setting them up for
            success – helping to improve the lives of women in their community. We are thrilled to share that this year:{" "}
          </p>
          <ul className={styles.coalitionsList}>
            <li>
              <strong className={styles.coalitionName}>Kenya Quality Ecosystem (KQE)</strong> deployed private sector solutions including the
              <S3CLink href='https://jacarandahealth.org/prompts/' disclaimer target='_blank'>
                <a> PROMPTS app</a>
              </S3CLink>{" "}
              in over 90 key facilities across Kisii, Kwale and Makueni, in alignment with local priorities. Building a path to sustainability, KQE
              has established over 45% cost.{" "}
            </li>
            <li>
              <strong className={styles.coalitionName}>FOR M(om)</strong> disbursed over $228,000 in loans to private and public facilities in Kano
              and Lagos states in Nigeria to enable facilities to buy pharmacy stock, equipment and other commodities, vastly improving the quality of
              care provided in facilities where administrative problems such as lack of blood, medicines and power contribute to up to 25% of maternal
              deaths.{" "}
            </li>
            <li>
              <strong className={styles.coalitionName}>Wellbody</strong> introduced new private sector solutions – low cost fetal monitoring devices –
              to Kono District, Sierra Leone through an innovative private partnership with{" "}
              <S3CLink href='https://www.janitri.in/' disclaimer target='_blank'>
                <a>Janitri</a>
              </S3CLink>{" "}
              to easily detect early signs of pregnancy complications at low cost – a life saving tool for mothers and babies.{" "}
            </li>
            <li>
              <strong className={styles.coalitionName}>MamaLink</strong> evacuated more than 3,000 mothers and newborns within 15 minutes to local
              hospitals in the last 5 months, responding to the vital need to improve emergency medical transportation in Nairobi where average
              transport time hovers at 162 minutes
            </li>
            <li>
              <strong className={styles.coalitionName}>Project Aisha</strong> institutionalized quality improvement structures in health facilities in
              Lagos and Kaduna states in Nigeria where poor quality of care contributes to high maternal death and obstetric complication rates.
            </li>
            <li>
              <strong className={styles.coalitionName}>e-SAATHI</strong> launched Women’s Ambassador Forum, welcoming new mothers and pregnant women
              from the community to share their insights on the program monitoring and tools. To date, e-SAATHI is supporting nearly 8000 women on
              their maternal health journeys and has begun partnering with the state government in Assam to expand the reach of the program even
              further.{" "}
            </li>
          </ul>

          <p>
            The initiative has focused on fostering community-led solutions and identifying best practices to improve maternal mortality, yielding
            exciting early successes from coalitions. To augment their success, coalitions convene as a Community of Practice to share lessons learned
            and build their own capacity to deliver sustainable solutions that are strengthening health system capacity and helping to improve
            maternal health outcomes. In November 2023, the coalitions met in Mumbai, India to share their successes over the past year and priorities
            for the future. Conversations in Mumbai focused on opportunities to scale these successes and ensure their sustainability. Looking ahead
            to 2024 and beyond, we look forward to supporting the coalition to expand their reach and ensuring that the best practices they have
            identified are shared with providers, implementers and mothers around the world.{" "}
          </p>
          <p>
            MSD is proud to support these efforts and remains committed to community-led solutions to help ensure that no women has to die while
            giving life.
          </p>
          <p>
            Sincerely, <br></br>
            Mark Allen & Temitayo Erogbogbo <br></br>
            MSD for Mothers <br></br>
          </p>
        </div>

        <br></br>

        <ImgGallery />
      </div>
    </>
  );
};

export default YearInReview;

import { useEffect, useState } from 'react';
import ScrollTrigger from 'react-scroll-trigger';

const Counter = ({ number }) => {
  const [currentNumber, setCurrentNumber] = useState(0);
  const [running, setRunning] = useState(false);

  useEffect(() => {
    if (running) {
      if (currentNumber < number)
        setTimeout(() => setCurrentNumber(currentNumber + 1), 70);
    }
  }, [number, currentNumber, running]);

  return (
    <ScrollTrigger onEnter={() => setRunning(true)}>
      {currentNumber}
    </ScrollTrigger>
  );
};

export default Counter;

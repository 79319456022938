import styled from 'styled-components';
import Button from '../Button';
import S3CLink from '../S3CLink';

const CompanyWrapper = styled.div`
  text-align: center;
  width: 255px;
  height: 290px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  @media screen and (max-width: 687px) {
    height: auto;
  }

  img {
    max-width: 200px;
    max-height: 120px;
  }

  p {
    text-align: center;
    margin: 20px 0;
  }

  .buttonSpacer {
    height: 44px;

    @media screen and (max-width: 687px) {
      height: 0;
    }
  }
`;

const IndiaCompany = (props) => {
  return (
    <CompanyWrapper>
      <img src={props.logo} alt={props.companyName + 'Logo'} />
      <p>{props.companyName}</p>
      {props.href ? (
        <S3CLink href={props.href} disclaimer>
          <Button variant='primary'>VIEW SITE</Button>
        </S3CLink>
      ) : (
        <div className='buttonSpacer'></div>
      )}
    </CompanyWrapper>
  );
};

export default IndiaCompany;

import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Carousel from '../../components/Carousel';
import articles from '../../news/newsList';
import { ReactComponent as FacebookIcon } from '../../assets/images/icons/facebook-logo-teal.svg';
import { ReactComponent as TwitterIcon } from '../../assets/images/icons/twitter-logo-teal.svg';
import { ReactComponent as LinkedinIcon } from '../../assets/images/icons/linkedin-logo-teal.svg';

const ArticleWrapper = styled.div`
  position: relative;

  .bannerImageWrapper {
    overflow: hidden;
    height: 600px;
    width: 100%;
    position: absolute;

    @media screen and (max-width: 1000px) {
      height: 400px;
    }

    @media screen and (max-width: 725px) {
      height: 250px;
    }

    .bannerImage {
      background-image: ${(props) => `url('${props.image}')`};
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 100%;
      z-index: 0;
      filter: blur(7px);
      transform: scale(1.1);

      .blurredImageOverlay {
        background-color: rgba(12, 35, 66, 0.6);
        height: 100%;
        width: 100%;
      }
    }
  }

  .articleContent {
    z-index: 10;
    position: relative;
    width: 920px;
    margin: 0 auto 60px auto;
    padding-top: 100px;

    @media screen and (max-width: 1000px) {
      width: 85%;
      padding-top: 50px;
    }

    .articleImageWrapper {
      width: 100%;
      margin-bottom: 60px;
      border: 3px solid var(--teal-color);
      position: relative;

      img {
        width: 100%;
      }

      .bannerImageSquare {
        position: absolute;
        height: 50px;
        width: 50px;
        right: -50px;
        bottom: -50px;
        background-color: var(--teal-color);

        @media screen and (max-width: 725px) {
          width: 20px;
          height: 20px;
          right: -20px;
          bottom: -20px;
        }
      }
    }

    .articleColumns {
      display: flex;

      @media screen and (max-width: 725px) {
        flex-direction: column;
      }

      .articleRight {
        width: 30%;

        @media screen and (max-width: 725px) {
          width: 100%;
        }

        .socialsWrapper {
          display: flex;
          gap: 20px;
          justify-content: center;
          margin-top: 100px;

          @media screen and (max-width: 725px) {
            margin-top: 40px;
          }
        }
      }
      .articleLeft {
        width: 70%;

        @media screen and (max-width: 725px) {
          width: 100%;
        }

        h1 {
          font-weight: bolder;
          font-size: 32px;
        }

        p {
          font-size: 16px;
        }
      }
    }
  }
`;

const NewsArticle = () => {
  const { title } = useParams();
  const currentArticle = articles.find((article) => article.title === title);

  return (
    <>
      <ArticleWrapper image={currentArticle.image}>
        <div className='bannerImageWrapper'>
          <div className='bannerImage'>
            <div className='blurredImageOverlay' />
          </div>
        </div>
        <div className='articleContent'>
          <div className='articleImageWrapper'>
            <img src={currentArticle.image} alt='Woman with baby' />
            <div className='bannerImageSquare' />
          </div>

          <div className='articleColumns'>
            <div className='articleLeft'>
              <h1>
                Lorem ipsum dolor sit amet, consecutor adipiscing elit. Integer
                egestas ut nulla id luctus.
              </h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Praesent lacinia, eros eget tempus finibus, nisl nisl volutpat
                odio, eget suscipit urna sem non erat. In faucibus ipsum tempor
                risus tempor, sed posuere diam tempus. In non diam et diam
                vestibulum mattis. Aliquam vitae rutrum nunc, non sagittis erat.
                Sed rutrum lacus vitae enim semper, sed volutpat libero
                fermentum. Nulla molestie vel felis ut maximus. Quisque in lacus
                sed ante sodales mollis.{' '}
              </p>
              <p>
                Aenean convallis, purus ut rhoncus bibendum, enim turpis
                scelerisque felis, in aliquet arcu magna a sapien. Vestibulum
                eleifend erat in luctus pulvinar. Nulla facilisi. Curabitur
                ultrices tellus in ipsum pharetra, sed scelerisque ex pulvinar.
                Cras hendrerit suscipit tortor, at lobortis lacus elementum sed.
                Mauris vel aliquet urna. Nunc sed tortor a diam bibendum
                tincidunt. Nulla lacinia risus nec dui porta, non consequat leo
                vulputate. Aliquam accumsan et velit et vehicula. In laoreet
                efficitur ante quis auctor. Donec lobortis vitae mi eu egestas.
                Nunc in neque at dui laoreet dictum dictum ac est. Nullam risus
                magna, hendrerit vitae urna eu, porta ullamcorper ex.
              </p>
              <p>
                Mauris varius diam vitae mi ullamcorper fermentum vitae at nunc.
                Donec ac erat vitae nunc porttitor molestie. Quisque elementum
                bibendum diam. Cras nec nunc euismod, ullamcorper risus sed,
                vulputate ex. Quisque rutrum ipsum sit amet massa commodo porta.
                Suspendisse interdum dolor ac dui porttitor commodo quis commodo
                magna. Praesent sit amet odio nec ex ultrices tristique
                hendrerit sit amet elit. Donec finibus elementum erat. Curabitur
                consequat lectus vitae lorem rutrum, nec tincidunt mauris
                blandit. Quisque et metus ligula. Ut pellentesque arcu elit, id
                feugiat justo placerat a. Nam fermentum odio aliquam convallis
                malesuada. Donec lectus urna, sagittis vel fringilla quis,
                mollis sed lorem. Sed mollis ligula ac lacus sodales, id dictum
                sem malesuada. Suspendisse consectetur, neque at hendrerit
                tempus, felis quam volutpat tellus, ac vestibulum felis odio nec
                nibh.
              </p>
              <p>
                Aenean dictum libero eu urna convallis convallis vitae non
                augue. Pellentesque vulputate tempus faucibus. Nullam quis purus
                mi. Sed cursus interdum enim, a euismod felis facilisis sit
                amet. Proin ultricies justo et volutpat suscipit. Nullam
                fermentum, orci at hendrerit lacinia, quam est pharetra purus,
                sit amet iaculis urna nibh in diam. Donec pellentesque sodales
                urna id ultrices. Vestibulum rhoncus, magna non dapibus rhoncus,
                nibh diam semper turpis, vitae fermentum sem arcu ac nulla. In
                vitae ex in risus feugiat consequat sed eget dui. Suspendisse
                placerat cursus convallis. Duis maximus fermentum posuere. Sed
                bibendum venenatis massa, sed laoreet ipsum tempus imperdiet.
                Vivamus tempor ex sit amet ligula sollicitudin, ac volutpat mi
                dignissim. Sed sagittis est a leo tincidunt, non pulvinar ipsum
                tempor. Donec vulputate purus a ligula luctus, sit amet accumsan
                diam tempus. Vivamus sit amet magna nec neque semper condimentum
                et cursus nunc.
              </p>
              <p>
                Maecenas eget vestibulum lectus. Pellentesque vestibulum at est
                ac lacinia. Pellentesque fringilla tellus sit amet pellentesque
                sodales. Praesent volutpat sapien nec turpis rhoncus vehicula.
                Ut dolor nisi, porttitor vitae magna in, consectetur porttitor
                quam. Curabitur id urna rutrum, elementum dui volutpat, placerat
                dolor. Vestibulum augue tortor, sagittis a bibendum non,
                accumsan at tortor. Nullam vitae euismod diam, eget pulvinar
                arcu. Vestibulum nec risus nec justo posuere congue. Suspendisse
                laoreet urna et vulputate aliquet. Nullam a felis a augue mollis
                tempus. Sed facilisis cursus ligula eget iaculis. Praesent ac
                dui porta, sollicitudin lorem vitae, condimentum erat.
              </p>
            </div>
            <div class='articleRight'>
              <div class='socialsWrapper'>
                <FacebookIcon width={40} height={40} />
                <TwitterIcon width={40} height={40} />
                <LinkedinIcon width={40} height={40} />
              </div>
            </div>
          </div>
        </div>
      </ArticleWrapper>
      <Carousel />
    </>
  );
};

export default NewsArticle;

import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import DisclaimerContext from "../utils/DisclaimerContext";
import PageContext from "../utils/PageContext";

const ExternalLink = (props) => {
  const disclaimer = useContext(DisclaimerContext);

  useEffect(() => {
    if (disclaimer.showDisclaimer === true) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [disclaimer.showDisclaimer]);

  return (
    <>
      <span
        style={{ cursor: "pointer" }}
        className='external-link'
        onClick={() => {
          disclaimer.setDisclaimerLink(props.href);
          disclaimer.setShowDisclaimer(true);
        }}
      >
        {props.children}
      </span>
    </>
  );
};

const S3CLink = (props) => {
  const page = useContext(PageContext);

  if (props.disclaimer) {
    return <ExternalLink {...props}>{props.children}</ExternalLink>;
  } else if (props.internal) {
    return (
      <Link to={props.href} onClick={() => page.setCurrentPage(props.href)}>
        {props.children}
      </Link>
    );
  } else {
    return (
      <a href={props.href} target='_blank' rel='noreferrer'>
        {props.children}
      </a>
    );
  }
};

export default S3CLink;

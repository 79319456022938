import ExternalLink from "../../ExternalLink";
import S3CLink from "../../S3CLink";

const ESaathiResources = (
  <div className='coalition-resources'>
    <h4>Nivi Manuscripts</h4>
    <ul>
      <li>
        Predicting Healthcare-Seeking Behavior Based on Stated Readiness to Act: Development and Validation of a Prediction Model.” Translational
        Behavioral Medicine, no. ibab096 (July 20, 2021).{" "}
        <S3CLink disclaimer href='https://doi.org/10.1093/tbm/ibab096'>
          https://doi.org/10.1093/tbm/ibab096
        </S3CLink>
        . Preprint available at
        <S3CLink disclaimer href='http://osf.io/9pazf'>
          http://osf.io/9pazf
        </S3CLink>
      </li>
      <li>
        ‘What Is the Best Method of Family Planning for Me?’: A Text Mining Analysis of Messages between Users and Agents of a Digital Health Service
        in Kenya.” Gates Open Research 3, no. May (2019).{" "}
        <S3CLink disclaimer href='https://doi.org/10.12688/gatesopenres.12999.1'>
          https://doi.org/10.12688/gatesopenres.12999.1
        </S3CLink>
      </li>
      <li>
        Pilot Randomized Encouragement Trial.” Journal of Medical Internet Research 20, no. 7 (2018): 1–11.{" "}
        <S3CLink disclaimer href='https://doi.org/10.2196/10756'>
          https://doi.org/10.2196/10756
        </S3CLink>
      </li>
      <li>
        Links to the{" "}
        <S3CLink disclaimer href='https://nivi.substack.com/'>
          Nivi newsletter
        </S3CLink>
        ,{" "}
        <S3CLink disclaimer href='https://medium.com/nivi-inc'>
          stories
        </S3CLink>
        .
      </li>
    </ul>
    <h4>PCC team manuscripts</h4>
    <ul>
      <li>
        Ramesh S, Chakraborty S, Adanu RM, .. Saggurti N.et al. Authorization of midwives to perform basic emergency obstetric and newborn care signal
        functions in Argentina, Ghana, and India: A multi-country validation study of a key global maternal and newborn health indicator. PLoS One.
        2023.{" "}
        <S3CLink disclaimer href='https://doi.org/10.1371/journal.pone.0283029'>
          https://doi.org/10.1371/journal.pone.0283029
        </S3CLink>
      </li>
      <li>
        Gausman J, Ramesh S, ,..Saggurti N et al, Measuring adequacy of the midwifery workforce using standards of competency and scope of work:
        Exploring the density and distribution of midwives in three low- and middle-income countries using cross-sectional and geospatial data. PLoS
        One. 2023.{" "}
        <S3CLink disclaimer href='https://doi.org/10.1371/journal.pone.0284034'>
          https://doi.org/10.1371/journal.pone.0284034
        </S3CLink>
      </li>
      <li>
        Caitlin R. Williams, Paula Vázquez, ….Sowmya Ramesh, Niranjan Saggurti et al, Improving measures of access to legal abortion: A validation
        study triangulating multiple data sources to assess a global indicator. PLoS One. 2023.{" "}
        <S3CLink disclaimer href='https://doi.org/10.1371/journal.pone.0280411'>
          https://doi.org/10.1371/journal.pone.0280411
        </S3CLink>
      </li>
      <li>
        Jolivet RR, Gausman J, ……… Ramesh S , .. Saggurti N. et al, Multisite, mixed methods study to validate 10 maternal health system and policy
        indicators in Argentina, Ghana and India: a research protocol, BMJ Open, 2022.{" "}
        <S3CLink disclaimer href='https://doi.org/10.1136/bmjopen-2021-049685'>
          10.1136/bmjopen-2021-049685
        </S3CLink>
      </li>
      <li>
        Sarna A., Porwal A., Ramesh S et al, Characterization of the types of anemia prevalent among children and adolescents 1-19 years of age in
        India: findings from the Comprehensive National Nutrition Survey, 2016-18 Lancet Child Adolescent Health 2020. 10.1016/S2352-4642(20)30094-8.
        PMID: 32562633
      </li>
      <li>
        Sachdev HS, Porwal A, Acharya R, Sana A, Ramesh S, et al, Haemoglobin Thresholds to Define Anaemia in a National Sample of Healthy 1-19 Years
        Old Children and Adolescents in India: A Population-Based Study. Lancet Global Health. 2020. 10.1016/S2214-109X(21)00077-2
      </li>
      <li>
        Ransi Ann Abraham, Praween K. Agrawal, Robert Johnston, Sowmya Ramesh et al, Comparison of hemoglobin concentrations measured by HemoCue and a
        hematology analyzer in Indian children and adolescents 1-19 years of age. International Journal of Laboratory Hematology. 2020
        <S3CLink disclaimer href='https://doi.org/10.1136/jclinpath-2017-204786'>
          10.1136/jclinpath-2017-204786
        </S3CLink>
      </li>
    </ul>
    <h4>Women Ambassador Forum launch and 1st meeting:</h4>
    <ul>
      <li>
        Women Ambassador Forum was launched and 1st WAF meeting was held. Please find attached few photographs from the event and small write-up
        labelled “WAF_e-SAATHI_Launch”
      </li>
    </ul>
    <h4>SBISR manuscript:</h4>
    <ul>
      <li>
        Nagpal J, Sachdeva A, Sengupta Dhar R, Bhargava VL, Bhartia A. Widespread non-adherence to evidence-based maternity care guidelines: a
        population-based cluster randomised household survey. BJOG. 2015;122(2):238-247. doi:10.1111/1471-0528.13054
      </li>
    </ul>
  </div>
);

export default ESaathiResources;
